import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import ContactForm from '../component/ContactForm';
import exhibitBgImage from "/exhibition.jpg";
import salesProductionImage from '/public/stand.jpg';
import marketReviewImage from '/public/stand_2.jpg';
import investorsRoomImage from '/public/cinetour_stand.jpg';

const HowToExhibitPage = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [currency, setCurrency] = useState({ code: 'USD', symbol: '$' });
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(true);

  const exhibitionOptions = [
    {
      name: "Premium Entrance Space",
      location: "Positioned prominently at the entrance of the hall",
      description: "This high-visibility spot guarantees maximum exposure as attendees enter the event. It's ideal for capturing immediate attention and making a strong first impression.",
      features: "Includes prime signage placement and high foot traffic, perfect for showcasing key messages and attracting visitors right from the start."
    },
    {
      name: "Cubic Exhibit Space",
      location: "Located within the main exhibition area",
      description: "A versatile cubic space designed for an interactive and immersive experience. Ideal for detailed presentations and engaging one-on-one interactions.",
      features: "Offers a flexible layout to accommodate various display needs, with options for customization, branding, and interactive elements."
    },
    {
      name: "Prime Location Space",
      location: "Situated near the front of the exhibit hall, but not directly at the entrance",
      description: "This prominent area ensures high visibility and easy access, making it perfect for engaging with attendees who are actively exploring the exhibits.",
      features: "Excellent placement with good visibility, allowing for effective engagement with visitors who are navigating through the main areas of the hall."
    },
    {
      name: "Value Space",
      location: "Located towards the rear of the exhibit hall",
      description: "An affordable option that still offers excellent visibility and engagement potential. This space provides a cost-effective solution for exhibitors who want to make an impact without the premium price.",
      features: "Provides a great opportunity for meaningful interactions and steady foot traffic from visitors exploring the hall, making it a smart choice for budget-conscious exhibitors."
    }
  ];

  const promotionalOptions = [
    {
      name: "Film Screening",
      description: "Showcase your film to a captivated audience during dedicated screening sessions at AFFF. This is an exceptional opportunity to present your work to industry professionals, potential investors, and film enthusiasts in a high-profile setting.",
      benefits: "Gain exposure and valuable feedback from a diverse audience, including key decision-makers in the film industry. It's also a chance to generate buzz and build interest around your project."
    },
    {
      name: "Advertising Opportunities",
      description: "Maximize your visibility at AFFF through various advertising options. Choose from a range of placements including event programs, digital displays, and on-site banners.",
      benefits: "Reach attendees effectively by positioning your brand in high-traffic areas and prominent locations throughout the event. Tailor your advertising to highlight your projects or services and enhance your presence among industry leaders and potential partners."
    }
  ];

  const magazineAdvertOptions = [
    {
      type: "Full Page",
      price: 500, // in USD
      description: "Premium full-page advertisement in the official AFFF 2025 Magazine, distributed to all attendees and industry partners."
    },
    {
      type: "Quarter Page",
      price: 150, // in USD
      description: "Quarter-page advertisement in the official AFFF 2025 Magazine, perfect for targeted messaging."
    }
  ];

  useEffect(() => {
    const fetchUserLocationAndExchangeRate = async () => {
      try {
        const locationResponse = await fetch('https://ipapi.co/json/');
        const locationData = await locationResponse.json();
        
        let currencyCode = 'USD';
        let currencySymbol = '$';

        if (locationData.country_code === 'NG') {
          currencyCode = 'NGN';
          currencySymbol = '₦';
        } else if (locationData.country_code === 'GB') {
          currencyCode = 'GBP';
          currencySymbol = '£';
        } else if (locationData.currency === 'EUR') {
          currencyCode = 'EUR';
          currencySymbol = '€';
        }

        setCurrency({ code: currencyCode, symbol: currencySymbol });

        if (currencyCode !== 'USD') {
          const rateResponse = await fetch(`https://api.exchangerate-api.com/v4/latest/USD`);
          const rateData = await rateResponse.json();
          setExchangeRates(rateData.rates);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchUserLocationAndExchangeRate();
  }, []);

  const convertCurrency = (priceUSD) => {
    if (loading) return 'Loading...';
    
    if (exchangeRates[currency.code]) {
      const convertedPrice = priceUSD * exchangeRates[currency.code];
      return `${currency.symbol}${convertedPrice.toLocaleString(undefined, {maximumFractionDigits: 0})}`;
    }
    
    return `$${priceUSD.toLocaleString()}`;
  };

  return (
    <div className="relative font-lexend">
      <PageHeader 
        title="HOW TO EXHIBIT" 
        description="Discover the various exhibition options and promotional opportunities at AFFF."
        backgroundImage={exhibitBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-4 sm:pl-6 mb-6 sm:mb-8">
              <h2 className="font-header text-3xl sm:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">EXHIBITION OPTIONS</h2>
            </div>
            
            <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg shadow-lg p-6">
              <h3 className="text-2xl font-bold text-custom-red mb-6 text-left">
                SECURE YOUR EXHIBITION STAND AT SHOWCASE COMPANY PRODUCT/SERVICE & PROJECT TO OVER 3000 AUDIENCE AT AFFF 2025 EXHIBITION/SHOWCASE
              </h3>
              
              <div className="mt-8">
                <h4 className="text-xl font-bold mb-4 text-left">Benefits includes:</h4>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <div className="h-6 w-6 rounded-full bg-custom-red text-white flex items-center justify-center mr-3 mt-1 flex-shrink-0">
                      1
                    </div>
                    <div className="text-left">
                      <span className="font-bold">High-Profile Exposure:</span> Unveil your new projects to 3,000 elite financiers and 
                      industry professionals from within and outside Africa, enhancing visibility and 
                      building buzz.
                    </div>
                  </li>
                  <li className="flex items-start">
                    <div className="h-6 w-6 rounded-full bg-custom-red text-white flex items-center justify-center mr-3 mt-1 flex-shrink-0">
                      2
                    </div>
                    <div className="text-left">
                      <span className="font-bold">Direct Access and Networking:</span> Present directly to key decision-makers, gain 
                      valuable feedback, and explore strategic partnerships and distribution opportunities.
                    </div>
                  </li>
                  <li className="flex items-start">
                    <div className="h-6 w-6 rounded-full bg-custom-red text-white flex items-center justify-center mr-3 mt-1 flex-shrink-0">
                      3
                    </div>
                    <div className="text-left">
                      <span className="font-bold">Media and Market Impact:</span> Leverage extensive media coverage and insights from 
                      industry experts to maximize your project's impact and market potential.
                    </div>
                  </li>
                  <li className="flex items-start">
                    <div className="h-6 w-6 rounded-full bg-custom-red text-white flex items-center justify-center mr-3 mt-1 flex-shrink-0">
                      4
                    </div>
                    <div className="text-left">
                      <span className="font-bold">Promote Your Brand:</span> Benefit from the high visibility of the exhibition area to 
                      enhance your brand's profile. With media coverage and a significant presence at the 
                      event, your projects and services will be positioned prominently in front of key 
                      industry players.
                    </div>
                  </li>
                </ul>
              </div>
              
              <div className="mt-12 space-y-6">
                {/* B-Zone Exhibition Card */}
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col md:flex-row">
                  <div className="md:w-1/3 h-48 md:h-auto relative flex-shrink-0">
                    <img 
                      src={salesProductionImage} 
                      alt="B-Zone Exhibition" 
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60"></div>
                  </div>
                  <div className="p-6 md:w-2/3 bg-[#FAF0E6]">
                    <h3 className="text-2xl font-bold mb-2">B-Zone Exhibition</h3>
                    <p className="text-gray-700 mb-4 text-left">
                      Secure your spot and leverage the B-Zone exhibition space at the Africa Film Finance 
                      Forum (AFFF) to maximize your visibility and impact. The exhibition space at AFFF 
                      is a dedicated area where projects, products, and services are showcased to a high-profile audience.
                    </p>
                    <div className="flex justify-between items-center">
                      <span className="text-2xl font-bold text-custom-red">₦2,000,000</span>
                      <button 
                        onClick={() => setShowContactForm(true)}
                        className="bg-custom-red text-white px-6 py-2 rounded-md font-bold hover:bg-red-700 transition duration-300"
                      >
                        Book Stand
                      </button>
                    </div>
                  </div>
                </div>

                {/* Creative Hub Card */}
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col md:flex-row">
                  <div className="md:w-1/3 h-48 md:h-auto relative flex-shrink-0">
                    <img 
                      src={marketReviewImage} 
                      alt="Creative Hub" 
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60"></div>
                  </div>
                  <div className="p-6 md:w-2/3 bg-[#FAF0E6]">
                    <h3 className="text-2xl font-bold mb-2">Creative Hub (Shared Space)</h3>
                    <p className="text-gray-700 mb-4 text-left">
                      Reserve your space in a cost-effective exhibition space meticulously designed to 
                      connect creative professionals, investors, and industry leaders, cultivating a thriving 
                      environment for collaboration and innovation.
                    </p>
                    <div className="flex justify-between items-center">
                      <span className="text-2xl font-bold text-custom-red">₦500,000</span>
                      <button 
                        onClick={() => setShowContactForm(true)}
                        className="bg-custom-red text-white px-6 py-2 rounded-md font-bold hover:bg-red-700 transition duration-300"
                      >
                        Book Stand
                      </button>
                    </div>
                  </div>
                </div>

                {/* CineTour Expo Card */}
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col md:flex-row">
                  <div className="md:w-1/3 h-48 md:h-auto relative flex-shrink-0">
                    <img 
                      src={investorsRoomImage} 
                      alt="CineTour Expo" 
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60"></div>
                  </div>
                  <div className="p-6 md:w-2/3 bg-[#FAF0E6]">
                    <h3 className="text-2xl font-bold mb-2">CineTour Expo (Shared Space)</h3>
                    <p className="text-gray-700 mb-4 text-left">
                      Secure your premier space designed for tourism experts, destinations, and production 
                      teams to showcase their locations, experiences, and services as production Hub for 
                      African content to industry professionals, investors, and potential partners.
                    </p>
                    <div className="flex justify-between items-center">
                      <span className="text-2xl font-bold text-custom-red">₦500,000</span>
                      <button 
                        onClick={() => setShowContactForm(true)}
                        className="bg-custom-red text-white px-6 py-2 rounded-md font-bold hover:bg-red-700 transition duration-300"
                      >
                        Book Stand
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-8 sm:mb-12">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-4 sm:pl-6 mb-6 sm:mb-8">
              <h2 className="font-header text-3xl sm:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">OTHER PROMOTIONAL OPTIONS</h2>
            </div>
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg shadow-lg p-6 overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-blue-200">
                    <th className="p-3 text-left font-bold">Option</th>
                    <th className="p-3 text-left font-bold">Description</th>
                    <th className="p-3 text-left font-bold">Benefits</th>
                    <th className="p-3 text-left font-bold">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {promotionalOptions.map((option, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-blue-50"}>
                      <td className="p-3 font-semibold">{option.name}</td>
                      <td className="p-3">{option.description}</td>
                      <td className="p-3">{option.benefits}</td>
                      <td className="p-3">
                        <button 
                          onClick={() => setShowContactForm(true)}
                          className="bg-custom-red text-white px-4 py-2 rounded font-bold text-sm hover:bg-red-600 transition duration-300"
                        >
                          EXHIBIT YOUR WORK
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          <section className="mb-8 sm:mb-12">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-4 sm:pl-6 mb-6 sm:mb-8">
              <h2 className="font-header text-3xl sm:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">MAGAZINE ADVERTISEMENT</h2>
            </div>
            <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg shadow-lg p-6">
              <h3 className="text-2xl font-bold text-custom-red mb-6 text-left">
                ADVERTISE IN THE OFFICIAL AFFF 2025 MAGAZINE
              </h3>
              <p className="mb-6">
                Showcase your brand or project in the official AFFF 2025 Magazine – distributed to over 3,000 industry executives, 
                financiers, and key decision-makers. This high-quality publication provides lasting visibility and impact beyond 
                the event itself.
              </p>
              
              <div className="mt-8 space-y-6">
                {magazineAdvertOptions.map((option, index) => (
                  <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col md:flex-row">
                    <div className="p-6 md:w-full bg-[#FAF0E6]">
                      <h3 className="text-2xl font-bold mb-2">{option.type}</h3>
                      <p className="text-gray-700 mb-4 text-left">
                        {option.description}
                      </p>
                      <div className="flex justify-between items-center">
                        <span className="text-2xl font-bold text-custom-red">{convertCurrency(option.price)}</span>
                        <button 
                          onClick={() => setShowContactForm(true)}
                          className="bg-custom-red text-white px-6 py-2 rounded-md font-bold hover:bg-red-700 transition duration-300"
                        >
                          Book Advertisement
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showContactForm && (
          <ContactForm onClose={() => setShowContactForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default HowToExhibitPage;