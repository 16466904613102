import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import NavigationBar from "../component/NavigationBarConnex";
import Footer from "../component/Footer";
import ConnexHeroImg from "/afff_connex.webp";
import CocktailImg from "/cocktail-event.avif";
import RoundtableImg from "/AFFF-connex-roundtable.webp";
import LaunchpadImg from "/film-workshop.jpg";
import RegistrationModal from '../component/RegistrationModal';
import { STATIC_PATTERNS } from '../config/assets';

// Animation variants
const pageVariants = {
  initial: {
    opacity: 0,
    y: 20
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut"
    }
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.5
    }
  }
};

const cardVariants = {
  initial: { scale: 0.95, opacity: 0 },
  animate: { 
    scale: 1, 
    opacity: 1,
    transition: { 
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

const fadeInUpVariant = {
  initial: { opacity: 0, y: 20 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const contentTracks = [
  "Understanding Film Financing Models",
  "Pitching to Investors",
  "Budgeting and Financial Planning",
  "Navigating Legal Aspects",
  "Accessing Grants and Funds",
  "Distribution and Revenue Models",
  "Building a Sustainable Business Model",
  "Marketing Strategies for Film Finance",
  "Brand Collaboration for Film Finance",
  "Networking and Relationship Building",
  "Case Studies of Successful Film Financing"
];

const whyAttendPoints = [
  "Expert guidance on financing film projects across Africa and globally",
  "Unparalleled networking opportunities with key players in the creative and financial sectors",
  "Exclusive previews of opportunities that will be unveiled at the full AFFF Summit in March 2025"
];

function AfffConnex() {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const handleRegisterClick = () => {
    setShowRegistrationModal(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setShowRegistrationModal(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      className="font-lexend overflow-x-hidden"
    >
      <NavigationBar />

      {/* Hero Section */}
      <section className="relative h-screen">
        <img
          className="h-full w-full object-cover"
          src={ConnexHeroImg}
          alt="AFFF Connex"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70"></div>
        <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-4 max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="mb-4"
          >
            <span className="inline-block bg-custom-red px-4 py-2 rounded-full text-sm md:text-base tracking-wider mb-6">
              Pre-Event to AFFF Summit 2025
            </span>
          </motion.div>
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="font-header text-5xl md:text-7xl font-bold mb-6 tracking-wider leading-tight"
          >
            Africa Film Finance<br />Forum (Connex)
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="text-xl md:text-2xl mb-8 font-lexend tracking-wide max-w-3xl"
          >
            Join us for an exclusive gateway to connect, collaborate, and co-create with the movers 
            and shakers of the global film finance industry.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            className="text-lg md:text-xl mb-12 font-medium"
          >
            November 26-28, 2024 • Lagos, Nigeria
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            className="flex flex-col sm:flex-row gap-6 mb-16"
          >
            <Link
              to="/contact"
              className="z-10 bg-transparent border-2 border-white text-white px-8 py-4 rounded-tl-xl rounded-br-xl hover:bg-white hover:text-custom-red transition-all duration-300 text-lg tracking-wider transform hover:scale-105"
            >
              Become a Sponsor
            </Link>
            <button
              onClick={handleRegisterClick}
              className="z-10 bg-custom-red border-2 border-custom-red text-white px-8 py-4 rounded-tl-xl rounded-br-xl hover:bg-transparent hover:border-white transition-all duration-300 text-lg tracking-wider transform hover:scale-105"
            >
              Register Now
            </button>
          </motion.div>
        </div>
        
        {/* Decorative elements */}
        <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-black to-transparent"></div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
          className="absolute bottom-12 left-1/2 transform -translate-x-1/2"
        >
          <div className="animate-bounce w-6 h-6 border-2 border-white border-t-0 border-l-0 transform rotate-45 translate-y-1"></div>
        </motion.div>
      </section>

      {/* About AFFF Connex */}
      <section className="py-24 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
        <div className={`absolute inset-0 bg-[url('${STATIC_PATTERNS.grid}')] opacity-5`}></div>
        <div className="container mx-auto px-4 relative">
          <motion.div
            variants={fadeInUpVariant}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            className="max-w-4xl mx-auto text-center"
          >
            <h2 className="font-header text-4xl md:text-5xl font-bold mb-8 tracking-wider text-custom-base">
              About AFFF Connex
            </h2>
            <p className="text-lg md:text-xl leading-relaxed text-gray-700 mb-8">
              The Africa Film Finance Forum (AFFF) Connex is designed as a precursor to the highly 
              anticipated AFFF Summit in March 2025. This dynamic event will set the stage for 
              groundbreaking partnerships and investment opportunities that will redefine Africa's 
              film industry.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
              <motion.div
                variants={cardVariants}
                className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="text-3xl font-bold text-custom-red mb-2">3000+</div>
                <div className="text-gray-600">Industry Professionals</div>
              </motion.div>
              <motion.div
                variants={cardVariants}
                className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="text-3xl font-bold text-custom-red mb-2">50+</div>
                <div className="text-gray-600">Expert Speakers</div>
              </motion.div>
              <motion.div
                variants={cardVariants}
                className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="text-3xl font-bold text-custom-red mb-2">20+</div>
                <div className="text-gray-600">Countries Represented</div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Key Highlights */}
      <section className="py-24 bg-gray-50 relative overflow-hidden">
        <div className="container mx-auto px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="font-header text-4xl md:text-5xl font-bold text-center mb-16 tracking-wider text-custom-base"
          >
            Key Highlights of AFFF Connex
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {/* Cocktail Confluence */}
            <motion.div 
              variants={cardVariants}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              className="bg-white rounded-xl shadow-xl overflow-hidden transform hover:scale-105 transition duration-300"
            >
              <div className="relative">
                <img src={CocktailImg} alt="Cocktail Confluence" className="w-full h-56 object-cover" />
                <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-black/70 to-transparent"></div>
              </div>
              <div className="p-8">
                <h3 className="font-roboto text-[1.5rem] leading-none font-bold mb-4 text-custom-base">
                  Cocktail Confluence:<br /> <span className="text-orange-700 text-md tracking-wider">Networking in Style</span>
                </h3>
                <p className="mb-6 text-gray-600 leading-relaxed">
                  Mingle with top-tier investors, filmmakers, producers, and industry experts in a relaxed, 
                  elegant atmosphere that fosters meaningful connections.
                </p>
                <button 
                  onClick={handleRegisterClick} 
                  className="text-custom-red hover:text-custom-base transition duration-300 font-semibold tracking-wide flex items-center gap-2 group"
                >
                  Register to be invited 
                  <span className="transform translate-x-0 group-hover:translate-x-2 transition-transform duration-300">→</span>
                </button>
              </div>
            </motion.div>

            {/* Investors Roundtable */}
            <motion.div 
              variants={cardVariants}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              className="bg-white rounded-xl shadow-xl overflow-hidden transform hover:scale-105 transition duration-300"
            >
              <div className="relative">
                <img src={RoundtableImg} alt="Investors Roundtable" className="w-full h-56 object-cover" />
                <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-black/70 to-transparent"></div>
              </div>
              <div className="p-8">
                <h3 className="font-roboto text-[1.5rem] leading-none font-bold mb-4 text-custom-base">
                  Film Finance Executive<br /><span className="text-orange-700 text-md tracking-wider">Investors Roundtable</span>
                </h3>
                <p className="mb-6 text-gray-600 leading-relaxed">
                  Engage in strategic discussions on financing African film projects in this exclusive 
                  closed-door session with industry leaders.
                </p>
                <Link 
                  to="/contact" 
                  className="text-custom-red hover:text-custom-base transition duration-300 font-semibold tracking-wide flex items-center gap-2 group"
                >
                  Ask for your special invite
                  <span className="transform translate-x-0 group-hover:translate-x-2 transition-transform duration-300">→</span>
                </Link>
              </div>
            </motion.div>

            {/* Launchpad Workshop */}
            <motion.div 
              variants={cardVariants}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              className="bg-white rounded-xl shadow-xl overflow-hidden transform hover:scale-105 transition duration-300"
            >
              <div className="relative">
                <img src={LaunchpadImg} alt="Launchpad Workshop" className="w-full h-56 object-cover" />
                <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-black/70 to-transparent"></div>
              </div>
              <div className="p-8">
                <h3 className="font-roboto text-[1.5rem] leading-none font-bold mb-4 text-custom-base">
                  Film Finance<br /><span className="text-orange-700 text-md tracking-wider">Launchpad Workshop</span>
                </h3>
                <p className="mb-6 text-gray-600 leading-relaxed">
                  Gain actionable insights on pitching to investors, structuring film deals, and creating 
                  projects with international appeal.
                </p>
                <button 
                  onClick={handleRegisterClick} 
                  className="text-custom-red hover:text-custom-base transition duration-300 font-semibold tracking-wide flex items-center gap-2 group"
                >
                  Register to Attend
                  <span className="transform translate-x-0 group-hover:translate-x-2 transition-transform duration-300">→</span>
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Why Attend */}
      <section className="py-24 bg-custom-red text-white relative overflow-hidden">
        <div className={`absolute inset-0 bg-[url('${STATIC_PATTERNS.dots}')] opacity-10`}></div>
        <div className="container mx-auto px-4 relative">
        <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="font-header text-4xl md:text-5xl font-bold text-center mb-16 tracking-wider"
          >
            Why Attend AFFF Connex?
          </motion.h2>
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="max-w-3xl mx-auto"
          >
            <div className="space-y-8">
              {whyAttendPoints.map((point, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="flex items-start space-x-4 bg-white/10 p-6 rounded-lg backdrop-blur-sm"
                >
                  <span className="text-2xl text-white/80">•</span>
                  <span className="text-lg leading-relaxed">{point}</span>
                </motion.div>
              ))}
            </div>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="text-center mt-16"
          >
            <button
              onClick={handleRegisterClick}
              className="bg-white text-custom-red px-10 py-4 rounded-tl-xl rounded-br-xl text-lg font-bold hover:bg-transparent hover:text-white border-2 border-white transition-all duration-300 tracking-wider transform hover:scale-105"
            >
              Secure Your Spot Now
            </button>
          </motion.div>
        </div>
      </section>

      {/* Content Tracks */}
      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-4xl mx-auto mb-16"
          >
            <h2 className="font-header text-4xl md:text-5xl font-bold mb-6 tracking-wider text-custom-base">
              Content Tracks
            </h2>
            <p className="text-lg md:text-xl text-gray-600">
              Join our Launchpad workshop and learn critical areas of film financing, including:
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
            {contentTracks.map((track, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="flex items-start space-x-4">
                  <span className="text-custom-red font-bold text-lg">
                    {(index + 1).toString().padStart(2, '0')}
                  </span>
                  <p className="font-semibold text-gray-800 leading-relaxed">
                    {track}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="text-center mt-16"
          >
            <button
              onClick={handleRegisterClick}
              className="bg-custom-red text-white px-10 py-4 rounded-tl-xl rounded-br-xl text-lg font-bold hover:bg-transparent hover:text-custom-red border-2 border-custom-red transition-all duration-300 tracking-wider transform hover:scale-105"
            >
              Register for Workshops
            </button>
          </motion.div>
        </div>
      </section>

      {/* Pre-footer CTA */}
      <section className="py-20 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-3xl mx-auto"
          >
            <h2 className="font-header text-3xl md:text-4xl font-bold mb-6 tracking-wider text-custom-base">
              Don't Miss This Opportunity
            </h2>
            <p className="text-lg text-gray-600 mb-8">
              Join us at AFFF Connex and be part of shaping the future of African cinema. 
              Limited spots available!
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link
                to="/contact"
                className="bg-transparent border-2 border-custom-base text-custom-base px-8 py-4 rounded-tl-xl rounded-br-xl hover:bg-custom-base hover:text-white transition-all duration-300 text-lg tracking-wider"
              >
                Contact Us
              </Link>
              <button
                onClick={handleRegisterClick}
                className="bg-custom-red text-white px-8 py-4 rounded-tl-xl rounded-br-xl hover:bg-transparent hover:text-custom-red border-2 border-custom-red transition-all duration-300 text-lg tracking-wider"
              >
                Register Now
              </button>
            </div>
          </motion.div>
        </div>
      </section>

      <Footer />

      {/* Registration Modal */}
      {showRegistrationModal && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 p-4"
        >
          <motion.div 
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="bg-white rounded-xl shadow-2xl max-w-4xl w-full relative overflow-hidden"
          >
            <div className="absolute top-4 right-4 z-50">
              <button 
                onClick={handleCloseModal}
                className="bg-white/90 text-gray-800 p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                aria-label="Close modal"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <RegistrationModal onClose={handleCloseModal} />
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
}

export default AfffConnex;