import React, { useState, useEffect } from 'react';
import BaseForm from './BaseForm';
import apiService from '../services/apiService';
import countries from '../data/countries'; // Assuming you have a countries list
import { v4 as uuidv4 } from 'uuid';

// Multi-step form for Industry Player Accreditation
const IndustryAccreditationForm = ({ onClose, initialFormData }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Personal Information
    fullName: '',
    email: '',
    phone: '',
    country: '',
    position: '',
    
    // Company Information
    companyName: '',
    companyType: '',
    companyWebsite: '',
    companyLocation: '',
    otherCompanyType: '',
    
    // Film Industry Experience
    yearsExperience: '',
    expertise: {
      producer: false,
      director: false,
      writer: false,
      cinematographer: false,
      filmEditor: false,
      soundDesigner: false,
      productionAssistant: false,
      distributor: false,
      talent: false,
      other: false
    },
    otherExpertise: '',
    recentProjects: '',
    previousFunding: 'No',
    fundingDetails: '',
    currentProjects: '',
    
    // Event Objectives
    objectives: {
      findInvestment: false,
      networkWithProfessionals: false,
      learnFinancing: false,
      establishPartnerships: false,
      other: false
    },
    otherObjective: '',
    investorsRoomParticipation: 'No',
    subscribeToNewsletter: true
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [showReferenceModal, setShowReferenceModal] = useState(false);
  const [referenceCode, setReferenceCode] = useState('');
  const [showLoadForm, setShowLoadForm] = useState(false);
  const [loadReferenceCode, setLoadReferenceCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState('');
  const [submissionError, setSubmissionError] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  useEffect(() => {
    // If formData is provided from parent (resume case), use it to initialize form
    if (initialFormData && Object.keys(initialFormData).length > 0) {
      console.log('Initializing form with data:', initialFormData);
      setFormData(prev => ({
        ...prev,
        ...initialFormData
      }));
    }
  }, [initialFormData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.includes('.')) {
      // Handle nested objects (checkboxes)
      const [parent, child] = name.split('.');
      setFormData({
        ...formData,
        [parent]: {
          ...formData[parent],
          [child]: type === 'checkbox' ? checked : value
        }
      });
    } else {
      // Handle regular inputs
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    
    // Validate the current step
    const error = validateStep(currentStep);
    if (error) {
      setError(error);
      return;
    }
    
    // Clear any previous errors
    setError('');
    
    // Check if this is the last content step, and if so, 
    // change to review step instead of submitting
    const isLastContentStep = currentStep === 5; // Adjust this number to match your form
    
    if (isLastContentStep) {
      // Move to a review step instead of submitting
      setCurrentStep(6); // Use a new step number for review
      return;
    }
    
    // Otherwise, just go to the next step
    setCurrentStep(currentStep + 1);
  };

  const handleFinalSubmit = async (e) => {
    e.preventDefault();
    
    // Reset error states
    setSubmissionError('');
    setIsSubmitting(true);
    
    try {
      // Log the form data for debugging
      console.log('Submitting form data:', formData);
      
      // Make sure subscribeToNewsletter is properly included
      const dataToSubmit = {
        ...formData,
        // Ensure this is always a boolean
        subscribeToNewsletter: Boolean(formData.subscribeToNewsletter)
      };
      
      // Submit to API
      const response = await apiService.submitIndustryAccreditation(dataToSubmit);
      
      // Handle successful submission
      setSuccess('Your accreditation application has been submitted successfully! We will review your application and contact you soon.');
      setReferenceCode(response.data.referenceCode);
      
      // Reset form or close after success
      setTimeout(() => {
        onClose();
      }, 5000);
      
    } catch (error) {
      console.error('Error submitting form:', error);
      
      // Check for specific error types
      if (error.response) {
        // Handle 409 Conflict - duplicate email
        if (error.response.status === 409 && error.response.data.errorType === 'DUPLICATE_EMAIL') {
          setSubmissionError(
            'An application with this email address already exists. Please use a different email address or contact our support team for assistance.'
          );
        } 
        // Handle other API errors with messages
        else if (error.response.data && error.response.data.message) {
          setSubmissionError(error.response.data.message);
        } 
        // Handle generic API errors
        else {
          setSubmissionError(`Error (${error.response.status}): Please check your form and try again.`);
        }
      } else {
        // Handle network or other errors
        setSubmissionError('An error occurred while submitting your application. Please check your connection and try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateStep = (step) => {
    setError('');
    
    // Validation logic for each step
    switch(step) {
      case 1: // Personal Information
        if (!formData.fullName.trim()) return "Full name is required";
        if (!formData.email.trim()) return "Email is required";
        if (!/^\S+@\S+\.\S+$/.test(formData.email)) return "Invalid email format";
        if (!formData.phone.trim()) return "Phone number is required";
        if (!formData.country.trim()) return "Country is required";
        break;
        
      case 2: // Company Information
        // Company info can be optional
        if (formData.companyName && !formData.companyLocation) 
          return "Please provide company location";
        if (formData.companyType === 'Other' && !formData.otherCompanyType)
          return "Please specify the company type";
        break;
        
      case 3: // Film Industry Experience
        if (!formData.yearsExperience) 
          return "Please specify your years of experience";
        
        // Check if at least one expertise is selected
        const hasExpertise = Object.values(formData.expertise).some(value => value === true);
        if (!hasExpertise) 
          return "Please select at least one area of expertise";
          
        // If other expertise is selected, validate the text field
        if (formData.expertise.other && !formData.otherExpertise)
          return "Please specify your other expertise";
          
        // If yes to previous funding, ensure details are provided
        if (formData.previousFunding === 'Yes' && !formData.fundingDetails.trim())
          return "Please provide details about your previous funding";
        break;
        
      case 4: // Event Objectives
        // Check if at least one objective is selected
        const hasObjective = Object.values(formData.objectives).some(value => value === true);
        if (!hasObjective) 
          return "Please select at least one objective for attending";
          
        // If other objective is selected, validate the text field
        if (formData.objectives.other && !formData.otherObjective)
          return "Please specify your other objective";
        break;
    }
    
    return null; // No errors
  };

  const handleStepChange = (direction, e) => {
    if (direction === 'next') {
      handleNext(e || new Event('click'));
    } else {
      prevStep();
    }
  };

  const generateReferenceCode = () => {
    const shortUuid = uuidv4().split('-')[0];
    const dateStr = new Date().toISOString().slice(0,10).replace(/-/g, '');
    const companyIdentifier = formData.companyName 
      ? formData.companyName.replace(/[^a-zA-Z0-9]/g, '').substring(0, 5).toUpperCase() 
      : `${formData.fullName.split(' ')[0].substring(0, 5)}`.toUpperCase();
      
    return `IND-${dateStr}-${companyIdentifier}-${shortUuid}`;
  };

  const saveProgress = async () => {
    if (!formData.email) {
      setError('Please enter your email address to save your progress');
      return;
    }
    
    const code = applicationId || generateReferenceCode();
    
    if (!applicationId) {
      setApplicationId(code);
    }
    
    try {
      await apiService.saveAccreditationDraft({
        type: 'industry',
        referenceCode: code,
        data: formData,
        timestamp: new Date().toISOString()
      });
      
      setReferenceCode(code);
      setShowReferenceModal(true);
    } catch (error) {
      console.error('Error saving progress:', error);
      setError('Failed to save your progress. Please try again.');
    }
  };

  const loadSavedApplication = async (referenceCode) => {
    try {
      setIsLoading(true);
      setError('');
      
      const response = await apiService.getDraftByReferenceCode(referenceCode);
      
      if (response.data.success) {
        const { formData } = response.data.data;
        
        // Set the form data
        setFormData(formData);
        
        // Set application ID
        setApplicationId(referenceCode);
        
        setSuccess('Your saved application has been loaded successfully.');
      } else {
        setError('Failed to load your saved application. Please try again.');
      }
    } catch (error) {
      console.error('Error loading saved application:', error);
      setError('Failed to load your saved application. The reference code may be invalid.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderReferenceModal = () => {
    if (!showReferenceModal) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Your Application Reference</h3>
          <p className="mb-3">Your application has been saved. Please use this reference code to resume your application later:</p>
          
          <div className="bg-gray-100 p-3 rounded border text-center font-mono font-bold text-lg mb-4">
            {referenceCode}
          </div>
          
          <p className="text-sm mb-4">
            <strong>Important:</strong> Please save this reference code. You will need it to resume your application.
          </p>
          
          <div className="flex justify-between">
            <button
              onClick={() => {navigator.clipboard.writeText(referenceCode); alert('Reference code copied to clipboard!')}}
              className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Copy Code
            </button>
            
            <button
              onClick={() => setShowReferenceModal(false)}
              className="px-4 py-2 bg-custom-red text-white rounded-md hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderLoadApplicationForm = () => {
    if (!showLoadForm) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Load Saved Application</h3>
          <p className="mb-3">Enter your reference code to continue a saved application:</p>
          
          <div className="mb-4">
            <input 
              type="text" 
              value={loadReferenceCode}
              onChange={(e) => setLoadReferenceCode(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter reference code"
            />
          </div>
          
          {loadError && (
            <div className="mb-4 p-2 bg-red-50 text-red-700 border-l-4 border-red-500">
              {loadError}
            </div>
          )}
          
          <div className="flex justify-between">
            <button
              onClick={() => setShowLoadForm(false)}
              className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Cancel
            </button>
            
            <button
              onClick={() => loadSavedApplication(loadReferenceCode)}
              className="px-4 py-2 bg-custom-red text-white rounded-md hover:bg-red-600"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Load Application'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Render steps based on currentStep
  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Personal Information</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Full Name*</label>
              <input 
                type="text" 
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your full name"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Email Address*</label>
              <input 
                type="email" 
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your email address"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number*</label>
              <input 
                type="tel" 
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your phone number"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Country of Residence*</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Select your country</option>
                {countries.map(country => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Position/Role*</label>
              <input 
                type="text" 
                name="position"
                value={formData.position}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your position or role"
              />
            </div>
          </div>
        );
        
      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Company Information (If applicable)</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Company/Organization Name</label>
              <input 
                type="text" 
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter company name (if applicable)"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Company Type</label>
              <select
                name="companyType"
                value={formData.companyType}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Select company type</option>
                <option value="Film Production">Film Production</option>
                <option value="Film Distribution">Film Distribution</option>
                <option value="Studio">Studio</option>
                <option value="Film Equipment Supplier">Film Equipment Supplier</option>
                <option value="Talent Agency">Talent Agency</option>
                <option value="Other">Other (Please specify)</option>
              </select>
            </div>
            
            {formData.companyType === 'Other' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Please specify company type</label>
                <input 
                  type="text" 
                  name="otherCompanyType"
                  value={formData.otherCompanyType}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Specify company type"
                />
              </div>
            )}
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Company Website (if applicable)</label>
              <input 
                type="url" 
                name="companyWebsite"
                value={formData.companyWebsite}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="https://example.com"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Company Location (City/Country)</label>
              <input 
                type="text" 
                name="companyLocation"
                value={formData.companyLocation}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter city and country"
              />
            </div>
          </div>
        );
        
      case 3:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Film Industry Experience</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">How many years have you been involved in the film industry?*</label>
              <select
                name="yearsExperience"
                value={formData.yearsExperience}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Select experience</option>
                <option value="Less than 1 year">Less than 1 year</option>
                <option value="1-3 years">1-3 years</option>
                <option value="3-5 years">3-5 years</option>
                <option value="More than 5 years">More than 5 years</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">What is your area of expertise in the film industry?*</label>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="producer"
                    name="expertise.producer"
                    checked={formData.expertise.producer}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="producer" className="ml-2 text-sm text-gray-700">Producer</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="director"
                    name="expertise.director"
                    checked={formData.expertise.director}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="director" className="ml-2 text-sm text-gray-700">Director</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="writer"
                    name="expertise.writer"
                    checked={formData.expertise.writer}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="writer" className="ml-2 text-sm text-gray-700">Writer</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="cinematographer"
                    name="expertise.cinematographer"
                    checked={formData.expertise.cinematographer}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="cinematographer" className="ml-2 text-sm text-gray-700">Cinematographer</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="filmEditor"
                    name="expertise.filmEditor"
                    checked={formData.expertise.filmEditor}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="filmEditor" className="ml-2 text-sm text-gray-700">Film Editor</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="soundDesigner"
                    name="expertise.soundDesigner"
                    checked={formData.expertise.soundDesigner}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="soundDesigner" className="ml-2 text-sm text-gray-700">Sound Designer</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="productionAssistant"
                    name="expertise.productionAssistant"
                    checked={formData.expertise.productionAssistant}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="productionAssistant" className="ml-2 text-sm text-gray-700">Production Assistant</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="distributor"
                    name="expertise.distributor"
                    checked={formData.expertise.distributor}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="distributor" className="ml-2 text-sm text-gray-700">Distributor</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="talent"
                    name="expertise.talent"
                    checked={formData.expertise.talent}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="talent" className="ml-2 text-sm text-gray-700">Talent (Actor/Actress)</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="other"
                    name="expertise.other"
                    checked={formData.expertise.other}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="other" className="ml-2 text-sm text-gray-700">Other (Please specify)</label>
                </div>
              </div>
            </div>
            
            {formData.expertise.other && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Please specify your expertise</label>
                <input 
                  type="text" 
                  name="otherExpertise"
                  value={formData.otherExpertise}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter your expertise"
                />
              </div>
            )}
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Please briefly describe your recent/ongoing film projects*</label>
              <textarea
                name="recentProjects"
                value={formData.recentProjects}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                rows="3"
                placeholder="Describe your recent or ongoing projects"
              ></textarea>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Have you previously secured funding or partnerships for a film project?*</label>
              <select
                name="previousFunding"
                value={formData.previousFunding}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
            
            {formData.previousFunding === 'Yes' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Please provide details about the project(s) and funding source(s)*</label>
                <textarea
                  name="fundingDetails"
                  value={formData.fundingDetails}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  rows="3"
                  placeholder="Describe projects and funding sources"
                ></textarea>
              </div>
            )}
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">What type of film projects are you currently working on or seeking funding for?*</label>
              <textarea
                name="currentProjects"
                value={formData.currentProjects}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                rows="3"
                placeholder="Describe current projects or funding needs"
              ></textarea>
            </div>
          </div>
        );
        
      case 4:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Event Objectives</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">What are your main objectives for attending AFFF?*</label>
              <div className="space-y-2">
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="findInvestment"
                    name="objectives.findInvestment"
                    checked={formData.objectives.findInvestment}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="findInvestment" className="ml-2 text-sm text-gray-700">Find film investment opportunities</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="networkWithProfessionals"
                    name="objectives.networkWithProfessionals"
                    checked={formData.objectives.networkWithProfessionals}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="networkWithProfessionals" className="ml-2 text-sm text-gray-700">Network with filmmakers and industry professionals</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="learnFinancing"
                    name="objectives.learnFinancing"
                    checked={formData.objectives.learnFinancing}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="learnFinancing" className="ml-2 text-sm text-gray-700">Learn about film financing trends and opportunities</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="establishPartnerships"
                    name="objectives.establishPartnerships"
                    checked={formData.objectives.establishPartnerships}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="establishPartnerships" className="ml-2 text-sm text-gray-700">Establish co-investment partnerships</label>
                </div>
                
                <div className="flex items-center">
                  <input 
                    type="checkbox" 
                    id="otherObjective"
                    name="objectives.other"
                    checked={formData.objectives.other}
                    onChange={handleChange}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor="otherObjective" className="ml-2 text-sm text-gray-700">Other (Please specify)</label>
                </div>
              </div>
            </div>
            
            {formData.objectives.other && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Please specify your objective</label>
                <input 
                  type="text" 
                  name="otherObjective"
                  value={formData.otherObjective}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter your objective"
                />
              </div>
            )}
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Would you like to participate in AFFF's Investor's Room and Deal Table?*</label>
              <select
                name="investorsRoomParticipation"
                value={formData.investorsRoomParticipation}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </div>
        );
        
      case 5:
        return (
          <div className="review-step">
            <h3 className="text-xl font-bold mb-4">Review Your Application</h3>
            <p className="mb-4">Please review your application details before final submission:</p>
            
            {/* Personal Information */}
            <div className="mb-4 p-4 bg-gray-50 rounded">
              <h4 className="font-bold mb-2">Personal Information</h4>
              <p><strong>Name:</strong> {formData.fullName}</p>
              <p><strong>Email:</strong> {formData.email}</p>
              <p><strong>Phone:</strong> {formData.phone}</p>
              <p><strong>Country:</strong> {formData.country}</p>
            </div>
            
            {/* Company Information */}
            <div className="mb-4 p-4 bg-gray-50 rounded">
              <h4 className="font-bold mb-2">Company Information</h4>
              <p><strong>Company:</strong> {formData.companyName}</p>
              <p><strong>Position:</strong> {formData.position}</p>
            </div>
            
            {/* Confirmation checkbox */}
            <div className="mb-4">
              <label className="flex items-start">
                <input 
                  type="checkbox" 
                  className="mt-1 mr-2" 
                  checked={formData.confirmAccuracy || false}
                  onChange={e => handleChange({
                    target: {
                      name: 'confirmAccuracy',
                      value: e.target.checked
                    }
                  })}
                  required
                />
                <span>
                  I confirm that all the information provided is accurate and complete.
                </span>
              </label>
            </div>
            
            {/* Error message */}
            {error && <div className="text-red-500 mb-4">{error}</div>}
            
            {/* Navigation buttons for review step */}
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={prevStep}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Back
              </button>
              <div className="flex space-x-2">
                <button
                  type="button"
                  onClick={saveProgress}
                  className="px-4 py-2 bg-[#212121] text-white rounded-md hover:bg-gray-800"
                >
                  Save Progress
                </button>
                <button
                  type="button"
                  onClick={handleFinalSubmit}
                  disabled={isSubmitting || !formData.confirmAccuracy}
                  className={`px-6 py-2 bg-custom-red text-white rounded-md ${
                    isSubmitting || !formData.confirmAccuracy ? 'opacity-75 cursor-not-allowed' : 'hover:bg-red-600'
                  }`}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit Application'}
                </button>
              </div>
            </div>
          </div>
        );
        
      default:
        return null;
    }
  };

  // Add custom checkbox component
  const CustomCheckbox = ({ checked, onChange, label }) => {
    return (
      <label className="flex items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="form-checkbox h-4 w-4 text-custom-red rounded"
        />
        <span className="text-sm">{label}</span>
      </label>
    );
  };

  const renderSubscriptionOption = () => {
    return (
      <div className="mt-4 mb-6">
        <label className="flex items-start">
          <input
            type="checkbox"
            className="mt-1 mr-2"
            checked={formData.subscribeToNewsletter || false}
            onChange={(e) => handleChange({
              target: {
                name: 'subscribeToNewsletter',
                value: e.target.checked
              }
            })}
          />
          <span>Subscribe to our newsletter to receive updates about future events and opportunities</span>
        </label>
      </div>
    );
  };

  return (
    <>
      <BaseForm onClose={onClose} title="Industry Accreditation Application">
        <form onSubmit={(e) => e.preventDefault()} className="p-6">
          {/* Progress bar */}
          <div className="mb-6">
            <div className="flex justify-between mb-2">
              {[1, 2, 3, 4, 5].map(step => (
                <div 
                  key={step}
                  className={`rounded-full h-4 w-4 flex items-center justify-center text-xs ${
                    currentStep >= step 
                      ? 'bg-custom-red text-white' 
                      : 'bg-gray-200 text-gray-500'
                  }`}
                >
                  {step}
                </div>
              ))}
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div 
                className="h-2 bg-custom-red rounded-full transition-all duration-300"
                style={{ width: `${(currentStep / 5) * 100}%` }}
              ></div>
            </div>
          </div>
          
          {/* Form fields based on current step */}
          {renderStep()}
          
          {/* Error message */}
          {error && (
            <div className="mt-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
              <p>{error}</p>
            </div>
          )}
          
          {/* Success message */}
          {success && (
            <div className="mt-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
              <p>{success}</p>
            </div>
          )}
          
          {/* Submission error message */}
          {submissionError && (
            <div className="p-4 my-4 bg-red-50 border-l-4 border-red-500 text-red-700">
              <p className="font-medium">Error</p>
              <p>{submissionError}</p>
              {submissionError.includes('email') && submissionError.includes('exists') && (
                <p className="mt-2">
                  If you already submitted an application and need to make changes,
                  please contact <a href="mailto:events@africafilmfinanceforum.com" className="underline">events@africafilmfinanceforum.com</a>.
                </p>
              )}
            </div>
          )}
          
          {/* Navigation buttons (only show if not on review step) */}
          {currentStep !== 5 && (
            <div className="mt-8 flex justify-between">
              {/* Left side button - Previous or Cancel */}
              {currentStep > 1 ? (
                <button
                  type="button"
                  onClick={prevStep}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </button>
              ) : (
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
              )}
              
              {/* Right side buttons - grouped Save and Next */}
              <div className="flex space-x-2">
                <button
                  type="button"
                  onClick={saveProgress}
                  className="px-4 py-2 bg-[#212121] text-white rounded-md hover:bg-gray-800"
                >
                  Save Progress
                </button>
                
                <button
                  type="button"
                  onClick={(e) => handleStepChange('next', e)}
                  className="px-6 py-2 bg-custom-red text-white rounded-md hover:bg-red-600"
                >
                  {currentStep === 4 ? 'Review Application' : 'Next'}
                </button>
              </div>
            </div>
          )}
          
          {/* Subscription option */}
          {renderSubscriptionOption()}
        </form>
      </BaseForm>
      
      {/* Render reference modal */}
      {renderReferenceModal()}
      
      {/* Render load application form */}
      {renderLoadApplicationForm()}
    </>
  );
};

export default IndustryAccreditationForm; 