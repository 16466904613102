import axios from 'axios';
import { API_SETTINGS } from '../config/apiSettings';

// Create axios instance with default config
const apiClient = axios.create({
  baseURL: API_SETTINGS.BASE_URL,
  timeout: API_SETTINGS.REQUEST_CONFIG.TIMEOUT,
  headers: API_SETTINGS.HEADERS.DEFAULT
});

// Add request interceptor
apiClient.interceptors.request.use(
  config => {
    // Log requests in development
    if (process.env.NODE_ENV !== 'production') {
      console.log('🚀 Request:', {
        url: config.url,
        method: config.method,
        data: config.data
      });
    }
    return config;
  },
  error => Promise.reject(error)
);

// Add response interceptor
apiClient.interceptors.response.use(
  response => {
    // Log successful responses in development
    if (process.env.NODE_ENV !== 'production') {
      console.log('✅ Response:', {
        status: response.status,
        data: response.data
      });
    }
    return response;
  },
  async error => {
    // Implement retry logic
    const { config, response } = error;
    
    // Skip retry for certain status codes
    if (response && (response.status === 401 || response.status === 403 || response.status === 422)) {
      return Promise.reject(error);
    }
    
    // Add retry count to request config
    config.retryCount = config.retryCount || 0;
    
    // Check if we've maxed out the total number of retries
    if (config.retryCount >= API_SETTINGS.REQUEST_CONFIG.RETRY_ATTEMPTS) {
      return Promise.reject(error);
    }
    
    // Increase the retry count
    config.retryCount += 1;
    
    // Log retry attempts in development
    if (process.env.NODE_ENV !== 'production') {
      console.log(`🔄 Retrying request (${config.retryCount}/${API_SETTINGS.REQUEST_CONFIG.RETRY_ATTEMPTS}):`, config.url);
    }
    
    // Create new promise to handle retry
    const delayRetryRequest = new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, API_SETTINGS.REQUEST_CONFIG.RETRY_DELAY);
    });
    
    return delayRetryRequest.then(() => apiClient(config));
  }
);

// API service methods
const apiService = {
  // General methods
  get: (endpoint, params = {}) => apiClient.get(endpoint, { params }),
  post: (endpoint, data = {}) => apiClient.post(endpoint, data),
  put: (endpoint, data = {}) => apiClient.put(endpoint, data),
  delete: (endpoint) => apiClient.delete(endpoint),
  
  // Specific API methods
  register: (data) => apiClient.post(API_SETTINGS.ENDPOINTS.REGISTER, data),
  subscribe: (email) => apiClient.post(API_SETTINGS.ENDPOINTS.SUBSCRIBE, { 
    email,
    listId: API_SETTINGS.EMAIL_MARKETING.ECOMMS_LIST_ID
  }),
  submitFilmScreening: (data) => apiClient.post(API_SETTINGS.ENDPOINTS.FILM_SCREENING, data),
  submitContact: (data) => apiClient.post(API_SETTINGS.ENDPOINTS.CONTACT, data),
  initiatePayment: (data) => apiClient.post(`${API_SETTINGS.ENDPOINTS.PAYMENT}/initiate`, data),
  verifyPayment: (reference) => apiClient.get(`${API_SETTINGS.ENDPOINTS.PAYMENT}/verify/${reference}`),
  submitFinancierAccreditation: (data) => {
    const payload = { ...data };
    if (payload.subscribeToNewsletter) {
      payload.listId = API_SETTINGS.EMAIL_MARKETING.ECOMMS_LIST_ID;
    }
    return apiClient.post(API_SETTINGS.ENDPOINTS.FINANCIER_ACCREDITATION, payload);
  },
  submitExhibition: (data) => apiClient.post(API_SETTINGS.ENDPOINTS.EXHIBITION, data),
  submitIndustryAccreditation: (data) => {
    const payload = { ...data };
    if (payload.subscribeToNewsletter) {
      payload.listId = API_SETTINGS.EMAIL_MARKETING.ECOMMS_LIST_ID;
    }
    return apiClient.post(API_SETTINGS.ENDPOINTS.INDUSTRY_ACCREDITATION, payload);
  },
  saveAccreditationDraft: async (draftData) => {
    try {
      // Ensure we're sending the data with the key expected by the server
      const payload = {
        ...draftData,
        formData: draftData.formData || draftData.data  // Support both formats
      };
      
      // Remove the 'data' property if it exists to avoid duplication
      if (payload.data) {
        delete payload.data;
      }
      
      return await apiClient.post('/accreditation-draft', payload);
    } catch (error) {
      console.error('Error saving draft:', error);
      throw error;
    }
  },
  getAccreditationDraft: async (referenceCode) => {
    try {
      const response = await apiClient.get(`${API_SETTINGS.ENDPOINTS.ACCREDITATION_DRAFT}/${referenceCode}`);
      
      // Log the response for debugging
      console.log('Draft retrieval response:', response);
      
      // Handle case where response data might be directly in response.data
      if (response.data && !response.data.success && !response.data.data) {
        // If the response is in an unexpected format, wrap it in our expected format
        return {
          data: {
            success: true,
            data: {
              formData: response.data,
              type: referenceCode.includes('FIN') ? 'financier' : 
                    referenceCode.includes('IND') ? 'industry' : 'unknown'
            }
          }
        };
      }
      
      return response;
    } catch (error) {
      console.error('Error retrieving draft:', error);
      throw error;
    }
  },
  submitPitchApplication: (data) => {
    return apiClient.post(API_SETTINGS.ENDPOINTS.PITCH_SUBMISSION, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  },
  savePitchDraft: (data) => apiClient.post(API_SETTINGS.ENDPOINTS.PITCH_DRAFT, data),
  getPitchDraft: (referenceCode) => {
    // Make sure the URL is correctly formatted
    return apiClient.get(`${API_SETTINGS.ENDPOINTS.PITCH_DRAFT}/${encodeURIComponent(referenceCode.trim())}`);
  },
  getIndustryAccreditations: () => {
    // Use URL class to ensure proper URL construction
    const url = new URL(`${API_SETTINGS.BASE_URL}/accreditation/industry`, window.location.origin);
    return apiClient.get(url.toString());
  },
  getDraftByReferenceCode: async (referenceCode) => {
    const url = `${API_SETTINGS.BASE_URL}/accreditation-draft/${referenceCode}`;
    return await apiClient.get(url);
  }
};

export default apiService; 