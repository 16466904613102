import React, { useState, useEffect } from 'react';
import { FaStar, FaCheck, FaMinus, FaPlus } from 'react-icons/fa';
import GetBadgeModal from './GetBadgeModal';

// Import logos
import yanga from "../assets/yanga.png";
import trucsr from "../assets/TRUCSRwhite-Mq9en2fX 1.svg";
import genesis from "../assets/genesis.svg";
import silverbird from "../assets/silverbird.svg";
import bluepicture from "../assets/blue-pictures.png";
import viva from "../assets/viva.png";
import homeland from "../assets/homelands.svg";
import guardian from "../assets/guardian.svg";
import carnival from "../assets/festival.png";
import calabar from "../assets/carnival.png";
import fmf from "../assets/fmf.png";
import fdn from "../assets/fdan.png";
import Naira from "../assets/naira.png";
import Mango from "../assets/mango.png";
import industry from "../assets/industry.png";

const Pricing = ({ setShowPricingModal }) => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [badgeCounts, setBadgeCounts] = useState({
    "Premium Financier": 2,
    "Premium Elite Badge": 2,
    "Enterprise plan": 3
  });
  
  // Add these new states for currency conversion
  const [currency, setCurrency] = useState({ code: 'USD', symbol: '$' });
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Add this useEffect for geolocation and exchange rates
  useEffect(() => {
    const fetchUserLocationAndExchangeRate = async () => {
      try {
        const locationResponse = await fetch('https://ipapi.co/json/');
        const locationData = await locationResponse.json();
        
        let currencyCode = 'USD';
        let currencySymbol = '$';

        if (locationData.country_code === 'NG') {
          currencyCode = 'NGN';
          currencySymbol = '₦';
        } else if (locationData.country_code === 'GB') {
          currencyCode = 'GBP';
          currencySymbol = '£';
        } else if (locationData.currency === 'EUR') {
          currencyCode = 'EUR';
          currencySymbol = '€';
        }

        setCurrency({ code: currencyCode, symbol: currencySymbol });

        if (currencyCode !== 'USD') {
          const rateResponse = await fetch(`https://api.exchangerate-api.com/v4/latest/USD`);
          const rateData = await rateResponse.json();
          setExchangeRates(rateData.rates);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load exchange rates. Using default conversions.');
        setLoading(false);
      }
    };

    fetchUserLocationAndExchangeRate();
  }, []);
  
  // Add this function for currency conversion
  const convertCurrency = (priceUSD) => {
    if (loading) return 'Loading...';
    if (error) return `${currency.symbol}${priceUSD.toLocaleString(undefined, {maximumFractionDigits: 2})}`;
    
    if (exchangeRates[currency.code]) {
      const convertedPrice = priceUSD * exchangeRates[currency.code];
      return `${currency.symbol}${convertedPrice.toLocaleString(undefined, {maximumFractionDigits: 2})}`;
    }
    
    return `$${priceUSD.toLocaleString(undefined, {maximumFractionDigits: 2})}`;
  };

  const plans = [
    {
      name: "Premium Financier",
      monthlyPrice: 155,
      yearlyPrice: 140,
      features: [
        "Access to the Investors Lounge",
        "Access to Investors Room",
        "Access to Market Review Session",
        "Access to the Masterclass",
        "Access to AFFF Forum Session"
      ],
      description: "Essential for colleagues or individuals."
    },
    {
      name: "Premium Elite Badge",
      monthlyPrice: 232,
      yearlyPrice: 209,
      features: [
        "Access to the Deal Table",
        "Access to the Investors Lounge",
        "Access to Investors Room",
        "Access to Market Review Session",
        "Access to the Masterclass",
        "Access to AFFF Forum Session"
      ],
      description: "Value for money.",
      popular: true
    },
    {
      name: "PREMIUM ELITE PLUS",
      monthlyPrice: 265,
      yearlyPrice: 238,
      features: [
        "Access to the AFFF DEAL TABLE",
        "Access to the AFFF INVESTOR'S ROOM", 
        "Access to the AFFF FILM FINANCING SESSION",
        "Access to the AFFF FILM FINANCE MARKET",
        "Access to the AFFF FILM INVESTMENT SUMMIT",
        "Access to the AFFF LOUNGE"
      ],
      description: "Ideal for organizations."
    }
  ];

  const logos = [
    { name: 'fmf', src: fmf },
    { name: 'carnival', src: carnival },
    { name: 'calabar', src: calabar },
    { name: 'fdn', src: fdn },
    { name: 'genesis', src: genesis },
    { name: 'silverbird', src: silverbird },
    { name: 'bluepicture', src: bluepicture },
    { name: 'viva', src: viva },
    { name: 'homeland', src: homeland },
    { name: 'industry', src: industry },
  ];

  const updateBadgeCount = (planName, increment) => {
    setBadgeCounts(prevCounts => ({
      ...prevCounts,
      [planName]: Math.max(2, prevCounts[planName] + increment)
    }));
  };

  const calculatePrice = (plan) => {
    const basePrice = isAnnual ? plan.yearlyPrice : plan.monthlyPrice;
    const totalUSD = isAnnual ? basePrice * badgeCounts[plan.name] : basePrice;
    
    // Only convert the display price, not the calculation
    return totalUSD;
  };

  const openModal = (plan) => {
    setSelectedPlan({
      ...plan,
      price: calculatePrice(plan),
      isAnnual: isAnnual,
      badgeCount: badgeCounts[plan.name]
    });
  };

  const closeModal = () => {
    setSelectedPlan(null);
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-16">
      <div className="flex flex-col md:flex-row justify-between items-center mb-12">
        <h1 className="text-4xl md:text-5xl font-bold mb-4 md:mb-0">
          Three Financier<br/>
          <span className="font-cursive">badges...</span>
        </h1>
        <div className="flex items-center">
          <div className="flex -space-x-1 overflow-hidden">
            {[...Array(5)].map((_, i) => (
              <img
                key={i}
                className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                src={`https://randomuser.me/api/portraits/men/${i+1}.jpg`}
                alt={`User ${i+1}`}
              />
            ))}
          </div>
          <div className="ml-2 flex items-center">
            {[...Array(5)].map((_, i) => (
              <FaStar key={i} className="text-yellow-400" />
            ))}
            <span className="ml-1 text-sm font-medium">4.5</span>
          </div>
          <span className="ml-1 text-sm text-gray-500">from 4,000+ interested</span>
        </div>
      </div>

      <div className="flex justify-center mb-12">
        <button 
          aria-label={`Switch to ${isAnnual ? 'monthly' : 'annual'} pricing`}
          className={`px-4 py-2 ${!isAnnual ? 'bg-black text-white' : 'bg-gray-200'} rounded-l-full transition duration-300 ease-in-out`}
          onClick={() => setIsAnnual(false)}
        >
          Single
        </button>
        <button 
          aria-label={`Switch to ${!isAnnual ? 'monthly' : 'annual'} pricing`}
          className={`px-4 py-2 ${isAnnual ? 'bg-black text-white' : 'bg-gray-200'} rounded-r-full transition duration-300 ease-in-out`}
          onClick={() => setIsAnnual(true)}
        >
          Bulk
        </button>
        {isAnnual && <span className="ml-2 text-sm text-green-500">Save 10%</span>}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {plans.map((plan, index) => (
          <div key={index} className="border rounded-lg p-6 relative bg-white hover:shadow-lg transition duration-300 ease-in-out">
            {plan.popular && (
              <span className="absolute top-0 right-0 bg-blue-500 text-white text-xs px-2 py-1 rounded-bl-lg rounded-tr-lg">
                Popular
              </span>
            )}
            <h2 className="text-2xl font-bold mb-4">{plan.name}</h2>
            <p className="text-4xl font-bold mb-1">
              {convertCurrency(calculatePrice(plan))}
              <span className="text-sm font-normal text-gray-500">
                {isAnnual ? ' total' : ' per plan'}
              </span>
            </p>
            <p className="text-sm text-gray-500 mb-4">{plan.description}</p>
            {isAnnual && (
              <div className="flex items-center justify-between mb-4">
                <span>Number of badges:</span>
                <div className="flex items-center">
                  <button onClick={() => updateBadgeCount(plan.name, -1)} className="p-1">
                    <FaMinus />
                  </button>
                  <span className="mx-2">{badgeCounts[plan.name]}</span>
                  <button onClick={() => updateBadgeCount(plan.name, 1)} className="p-1">
                    <FaPlus />
                  </button>
                </div>
              </div>
            )}
            <button
              onClick={() => openModal(plan)}
              className="w-full bg-black text-white py-2 rounded-lg mb-6 hover:bg-gray-800 transition duration-300 ease-in-out"
            >
              Get Your Badge{isAnnual ? 's' : ''}
            </button>
            <h3 className="font-bold mb-2">FEATURES</h3>
            <p className="text-sm text-gray-500 mb-2">
              {index === 0 ? "All general event benefits..." : 
               index === 1 ? "Special considerations..." :
               "Dedicated care & support..."}
            </p>
            <ul className="space-y-2">
              {plan.features.map((feature, i) => (
                <li key={i} className="flex items-center text-sm">
                  <FaCheck className="text-green-500 mr-2" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="mt-16 flex flex-wrap justify-center items-center gap-8">
        {logos.map((logo, index) => (
          <img key={index} src={logo.src} alt={logo.name} className="h-10" />
        ))}
      </div>

      <GetBadgeModal
        isOpen={selectedPlan !== null}
        onClose={closeModal}
        plan={selectedPlan}
      />
    </div>
  );
};

export default Pricing;