import React from "react";
import Home from "../component/Home";
import PromotionalBanner from '../component/PromotionalBanner';

function Homepage() {
  return (
    <>
      <PromotionalBanner />
      <Home />
    </>
  );
}

export default Homepage;
