import React from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import advisorsBgImage from "/who-can.jpg"

const AFFFAdvisorsPage = () => {
  const advisors = [
    { id: 1, name: "Mary Ephraim", role: "Convener, Africa Film Finance Forum" },
    { id: 2, name: "Joy Odiete", role: "ADVISOR" },
    { id: 3, name: "Funmi Onuma", role: "ADVISOR" },
    { id: 4, name: "Carina De Frietas", role: "ADVISOR" },
    { id: 5, name: "Udeme Etukeyen", role: "ADVISOR" },
    { id: 6, name: "Bernice Ubadinobi", role: "Project Manager" },
  ];

  return (
    <div className="relative bg-white">
      <PageHeader 
        title="AFFF ADVISORS" 
        description="Meet the experienced professionals guiding AFFF"
        backgroundImage={advisorsBgImage}

      />
      <main className="py-16 sm:py-24 flex justify-center">
        <div className="container max-w-[1280px] px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-1 border-gray-200">
            <div className="col-span-1 row-span-2 flex flex-col justify-between p-4 border-r border-b border-gray-200">
              <div>
                <h2 className="text-7xl font-header tracking-wide font-bold text-gray-200 leading-none">Advisors</h2>
                <h3 className="text-[5rem] font-header tracking-wide font-bold text-red-500 leading-none">AFFF SUMMIT 2025</h3>
              </div>
              <div className="mt-3">
                <p className="text-sm font-bold">L-R</p>
                {advisors.map((advisor) => (
                  <p key={advisor.id} className="text-xs">
                    <b>{advisor.name}</b><br />{advisor.role} <br/><br/>
                  </p>
                ))}
              </div>
            </div>
            <div className="col-span-2">
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <img
                    key={num}
                    src={`/advisors/${num}.png`}
                    alt={`Advisor ${num}`}
                    className="w-full h-[379px] object-cover"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AFFFAdvisorsPage;