import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import faqBgImage from "/faqs.jpg"

const FAQAccordion = ({ question, answer, index, isOpen, toggleAccordion }) => (
  <div className="bg-white rounded-lg shadow-lg mb-4">
    <button
      onClick={() => toggleAccordion(index)}
      className="w-full text-left p-4 bg-[#00323D] text-white font-bold flex justify-between items-center"
    >
      <span>{question}</span>
      <span>{isOpen ? '-' : '+'}</span>
    </button>
    {isOpen && (
      <div className="p-4 text-gray-700 border-t">
        {answer}
      </div>
    )}
  </div>
);

const FAQSection = ({ section }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <section className="mb-16">
      <h2 className="font-header text-3xl sm:text-4xl font-bold mb-8 text-center text-[#00323D]">
        {section.title}
      </h2>
      {section.questions.map((faq, index) => (
        <FAQAccordion
          key={index}
          index={index}
          question={faq.q}
          answer={faq.a}
          isOpen={openIndex === index}
          toggleAccordion={toggleAccordion}
        />
      ))}
    </section>
  );
};

const FAQPage = () => {
  const faqSections = [
    {
      title: "FINANCIER ACCREDITATION QUESTIONS",
      questions: [
        { q: "Who is an AFFF Financier?", a: "An AFFF Accredited Financier is a company or individual interested in funding various aspects of the film production and release process, including marketing, distribution, and more." },
        { q: "Who can register for AFFF Financier Accreditation?", a: "Financial Institutions, Investors, and Brands that support movies can register for Accreditation." },
        { q: "Is getting an accreditation badge the same as registering for AFFF?", a: "No. Accreditation takes place before registering as an accredited financier or sales and production company for AFFF." },
        { q: "Can I register for only one AFFF financier badge?", a: "Sure. Send a mail with your request to events@africafilmfinanceforum.com" },
        { q: "As an AFFF accredited Financier, what do I have access to?", a: "Visit Financier Badges Type for more information." },
        { q: "What are the prices for AFFF financier badges?", a: "Visit Financier Badges Type for more information." },
        { q: "When is the Deadline for Accreditation?", a: "Accreditation registration ends on April 20th, 2025." }
      ]
    },
    {
      title: "SALES AND PRODUCTION COMPANIES ACCREDITATION QUESTIONS",
      questions: [
        { q: "Who is an AFFF Accredited Sales and Production Company?", a: "An Accredited Sales and Production Company is a company or professional within the film industry that has obtained exclusive rights to distribute films." },
        { q: "Can I apply for multiple accreditations?", a: "Yes, you can apply for different accreditations based on your roles." }
      ]
    }
  ];

  return (
    <div className="relative bg-gray-100">
      <PageHeader
        title="Frequently Asked Questions"
        description="Get answers to common questions about Financier and Sales Accreditation."
        backgroundImage={faqBgImage}

      />
      <main className="py-12 px-4 sm:px-6 lg:px-8 font-lexend mb-36">
        <div className="max-w-4xl mx-auto">
          {faqSections.map((section, index) => (
            <FAQSection key={index} section={section} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default FAQPage;
