import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import PricingModal from "../component/PricingModal";
import dealTableBgImage from "../assets/deal-table.jpg";
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const ProcessTimeline = () => {
  const steps = [
    { 
      title: "Application Submission", 
      icon: "mdi:file-document-outline",
      content: "Submit detailed project proposal, financial projections, ROI analysis, cast & crew profile, current funding status, and investment needs at least 6 weeks prior to the AFFF event date."
    },
    { 
      title: "Initial Screening & Evaluation", 
      icon: "mdi:magnify",
      content: "AFFF Selection Committee reviews submissions based on ROI potential, uniqueness, scalability, budget viability, production team strength, and marketability."
    },
    { 
      title: "Shortlisting of Projects", 
      icon: "mdi:format-list-checks",
      content: "10-15 projects with the highest potential are shortlisted. Selected filmmakers receive a Deal Table Pass for premium networking and briefings."
    },
    { 
      title: "Deal Table Prep & Strategy Briefing", 
      icon: "mdi:strategy",
      content: "Shortlisted applicants attend a preparation session to refine pitches, strategize negotiations, and understand the Deal Table format."
    },
    { 
      title: "Deal Table Appointment Scheduling", 
      icon: "mdi:calendar-clock",
      content: "Selected projects are assigned 30-45 minute slots at the Deal Table to pitch directly to Premium Elite Plus financiers, top sales agents, and production companies."
    },
    { 
      title: "The Deal Table Session", 
      icon: "mdi:table-furniture",
      content: "Intimate boardroom-style environment for pitching projects, securing financing or distribution deals, and forging partnerships with top industry players."
    },
    { 
      title: "Post-Event Follow-Up", 
      icon: "mdi:handshake",
      content: "AFFF facilitates follow-up meetings between filmmakers and financiers to finalize deals and negotiate terms, with additional support for legal agreements."
    },
    { 
      title: "Additional Opportunities", 
      icon: "mdi:star-plus-outline",
      content: "Projects not selected for the Deal Table still have access to Investor's Room, Pitch Sessions, and Networking Lounges for other financing and collaboration opportunities."
    }
  ];

  return (
    <div className="mt-12">
      <div className="relative">
        {steps.map((step, index) => (
          <div key={index} className="mb-12 flex items-start">
            <div className="flex items-center justify-center w-20 h-20 bg-custom-red rounded-full flex-shrink-0">
              <Icon icon={step.icon} className="text-white text-4xl" />
            </div>
            <div className="ml-6 flex-grow">
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <div className="p-4 border border-gray-300 rounded-lg bg-white shadow-md">
                <p>{step.content}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="absolute top-0 bottom-0 left-10 w-0.5 bg-gray-200 -ml-0.5"></div>
      </div>
    </div>
  );
};

const DealTablePage = () => {
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [showProcessTimeline, setShowProcessTimeline] = useState(false);

  const toggleProcessTimeline = () => {
    setShowProcessTimeline(!showProcessTimeline);
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#FEFBF6] font-lexend">
      <PageHeader 
        title="THE DEAL TABLE" 
        description="An ultra-exclusive boardroom for high-budget film projects with substantial ROI potential."
        backgroundImage={dealTableBgImage}
      />
      <main className="flex-grow p-4 sm:p-6 flex flex-col items-center justify-center">
        <div className="container mx-auto px-4 py-8 max-w-6xl mx-6">
          
          {/* New promotional section */}
          <section className="mb-12 bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="p-6 sm:p-8">
              <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-custom-base">Secure Funding for Your Film, Infrastructure, or Distribution Project at AFFF 2025!</h2>
              
              <p className="mb-6">
                The Africa Film Finance Forum (AFFF) Deal Table is the premier investment platform 
                connecting high-potential film and creative industry projects with leading financiers, 
                investors, and decision-makers. Whether you're a filmmaker, production company, 
                distributor, or infrastructure developer, this is your opportunity to secure the funding 
                and partnerships needed to take your project to the next level!
              </p>
              
              <h3 className="text-xl font-bold mb-3 text-custom-red">Why Pitch at the AFFF Deal Table?</h3>
              <ul className="mb-6 space-y-2">
                <li className="flex items-start">
                  <span className="text-green-500 mr-2 font-bold">✅</span>
                  <span><strong>Direct Access to Investors</strong> - Meet and pitch to top-tier financiers, venture capitalists, private equity firms, and development banks actively seeking investment opportunities in film and media.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 mr-2 font-bold">✅</span>
                  <span><strong>Verified & Strategic Funding</strong> - Our team of film and finance experts scouts and verifies projects, ensuring they are investment-ready and attractive to financiers.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 mr-2 font-bold">✅</span>
                  <span><strong>High ROI Industry</strong> - The African film industry offers over 200% return on investment, making it one of the most lucrative sectors in the creative economy.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 mr-2 font-bold">✅</span>
                  <span><strong>Exclusive Investment Opportunities</strong> - Gain access to infrastructure financing, distribution platform funding, and film acquisition deals—key components for long-term industry growth.</span>
                </li>
                <li className="flex items-start">
                  <span className="text-green-500 mr-2 font-bold">✅</span>
                  <span><strong>Exposure to Over 3,000 Industry Executives</strong> - Engage with major players from across Africa and beyond, positioning your project in front of decision-makers who can help make it a reality.</span>
                </li>
              </ul>
              
              <h3 className="text-xl font-bold mb-3 text-custom-red">What Kind of Projects Are We Looking For?</h3>
              <ul className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-3">
                <li className="flex items-start">
                  <span className="mr-2">🎬</span>
                  <span><strong>Feature Films & Blockbusters</strong> – High-quality projects with strong market potential.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">🏗</span>
                  <span><strong>Film Infrastructure Financing</strong> – Studios, post-production hubs, cinemas, and exhibition spaces.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">📺</span>
                  <span><strong>Film Distribution Platforms</strong> – Streaming platforms, distribution networks, and content acquisitions.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">🎞</span>
                  <span><strong>Animation & Documentaries</strong> – Engaging storytelling with global appeal.</span>
                </li>
              </ul>
              
              <h3 className="text-xl font-bold mb-3 text-custom-red">How It Works</h3>
              <ul className="mb-6 space-y-2">
                <li className="flex items-start">
                  <span className="mr-2">1️⃣</span>
                  <span><strong>Submit Your Project</strong> – Fill out the application form and provide key details about your project.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">2️⃣</span>
                  <span><strong>Verification & Selection</strong> – Our expert panel will review and select the most viable projects.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">3️⃣</span>
                  <span><strong>Pitch at the Deal Table</strong> – Present your project to an exclusive group of financiers and investors.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">4️⃣</span>
                  <span><strong>Secure Funding & Partnerships</strong> – Close deals and move forward with financing support.</span>
                </li>
              </ul>
              
              <h3 className="text-xl font-bold mb-5 text-custom-red">Who Should Apply?</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                {/* Filmmakers Card */}
                <div className="bg-white p-4 rounded-lg shadow-md border-l-4 border-custom-red flex hover:shadow-lg transition-shadow">
                  <div className="flex-shrink-0 w-12 h-12 bg-custom-red rounded-full flex items-center justify-center mr-4">
                    <Icon icon="mdi:movie-open" className="text-white text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-bold text-lg mb-1">Filmmakers & Producers</h4>
                    <p className="text-gray-700">Looking for production financing.</p>
                  </div>
                </div>
                
                {/* Infrastructure Developers Card */}
                <div className="bg-white p-4 rounded-lg shadow-md border-l-4 border-custom-red flex hover:shadow-lg transition-shadow">
                  <div className="flex-shrink-0 w-12 h-12 bg-custom-red rounded-full flex items-center justify-center mr-4">
                    <Icon icon="mdi:building" className="text-white text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-bold text-lg mb-1">Infrastructure Developers</h4>
                    <p className="text-gray-700">Seeking investment in studios, cinemas, or production hubs.</p>
                  </div>
                </div>
                
                {/* Distribution Companies Card */}
                <div className="bg-white p-4 rounded-lg shadow-md border-l-4 border-custom-red flex hover:shadow-lg transition-shadow">
                  <div className="flex-shrink-0 w-12 h-12 bg-custom-red rounded-full flex items-center justify-center mr-4">
                    <Icon icon="mdi:television-classic" className="text-white text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-bold text-lg mb-1">Distribution Companies & Platforms</h4>
                    <p className="text-gray-700">Looking for expansion funding.</p>
                  </div>
                </div>
                
                {/* Animators Card */}
                <div className="bg-white p-4 rounded-lg shadow-md border-l-4 border-custom-red flex hover:shadow-lg transition-shadow">
                  <div className="flex-shrink-0 w-12 h-12 bg-custom-red rounded-full flex items-center justify-center mr-4">
                    <Icon icon="mdi:animation" className="text-white text-2xl" />
                  </div>
                  <div>
                    <h4 className="font-bold text-lg mb-1">Animators & Documentary Filmmakers</h4>
                    <p className="text-gray-700">Seeking global partnerships.</p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#FFF8F0] p-6 rounded-lg mb-6">
                <h3 className="text-2xl font-bold text-center mb-4 text-custom-red">APPLY NOW & GET FUNDED!</h3>
                <p className="text-center mb-4">Spots are limited! Don't miss this golden opportunity to pitch at Africa's most exclusive film financing marketplace.</p>
                <div className="flex flex-col sm:flex-row justify-center items-center gap-4 mb-4">
                  <div className="flex items-center">
                    <span className="mr-2">📅</span>
                    <span><strong>Event Date:</strong> AFFF 2025</span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2">📍</span>
                    <span><strong>Location:</strong> Lagos, Nigeria</span>
                  </div>
                </div>
                <div className="text-center">
                  <Link 
                    to="/pitch-submission" 
                    className="inline-block px-8 py-3 bg-custom-red text-white rounded-tl-lg rounded-br-lg font-bold text-lg hover:bg-red-700 transition duration-300"
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
              
              <p className="text-center font-bold text-xl text-custom-base">
                Join the AFFF Deal Table – Where Vision Meets Investment
              </p>
            </div>
          </section>
          
          {/* Toggle button for process timeline */}
          <div className="mb-6 text-center">
            <button 
              onClick={toggleProcessTimeline}
              className="bg-custom-red text-white px-6 py-3 rounded-tl-lg rounded-br-lg font-bold hover:bg-red-700 transition duration-300 flex items-center mx-auto"
            >
              <span>Securing a Deal Table Spot</span>
              <Icon icon={showProcessTimeline ? "mdi:chevron-up" : "mdi:chevron-down"} className="ml-2 text-xl" />
            </button>
          </div>
          
          {/* Process Timeline section with toggle visibility */}
          {showProcessTimeline && (
            <>
              <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-8">
                <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">
                  Process for Securing a Spot at The Deal Table
                </h2>
              </div>
              
              <ProcessTimeline />
            </>
          )}

          <section className="mt-12">
            <h2 className="text-2xl font-bold mb-4">Important Notes:</h2>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Exclusivity:</strong> Only the most promising projects with a clear pathway to profitability will be admitted to the Deal Table.</li>
              <li><strong>Premium Pass:</strong> Projects with Deal Table Access will receive premium passes, which include other exclusive networking opportunities, masterclasses, and panels.</li>
              <li><strong>Networking Benefit:</strong> Even after the event, all Deal Table participants remain part of the AFFF Elite Network, providing ongoing access to potential investors and collaborators throughout the year.</li>
              <li><strong>More Information:</strong> Bernice on <strong>+234 814 655 8068</strong> or send us an email at: <strong>funding@africafilmfinanceforum.com</strong></li>
            </ul>
          </section>
          
          <section className="text-center mt-16 mb-16 p-4 border border-gray-300 rounded-lg bg-white shadow-md">
            <p className="mb-1 p-12 sm:text-xl leading-relaxed">
              The Deal Table is an ultra-exclusive boardroom designed for high-budget film projects with substantial
              ROI potential. Access is limited to Premium Elite Plus financiers and top Sales & Production companies. It
              offers a sophisticated environment for high-level networking, in-depth negotiations, and strategic
              discussions, making it the ideal setting for securing significant investments and forging elite business
              partnerships.
            </p>
            <div className="flex justify-center mb-8 pb-2">
              <Icon icon="mdi:handshake-outline" className="text-[7rem] text-custom-red mr-4" />
              <Icon icon="mdi:movie-open-outline" className="text-[7rem] text-custom-red" />
            </div>
            <Link 
                to="/register" 
                className="px-10 py-2 mt-4 mb-6 pb-6 uppercase font-bold cursor-pointer bg-custom-red rounded-tl-lg rounded-br-lg text-white font-roboto text-[18px] inline-block"
              >
              GET YOUR BADGE
            </Link>
          </section>
        </div>
      </main>
      <Footer />
      <PricingModal isOpen={isPricingModalOpen} onClose={() => setIsPricingModalOpen(false)} />
    </div>
  );
};

export default DealTablePage;