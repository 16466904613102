// PitchSubmissionForm.jsx
import React, { useState, useRef, useCallback, useEffect } from 'react';
import BaseForm from './BaseForm';
import apiService from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import countries from '../data/countries';
import { Upload, Check, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const FileDropZone = ({ label, id, onChange, accept = "*", required = false, description = "" }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const fileInputRef = useRef(null);
  
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
    }
  }, [isDragging]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (file) {
      setFileName(file.name);
      setFileSize(formatFileSize(file.size));
      
      // Create a fake event to pass to the onChange handler
      const event = { 
        target: { 
          name: id, 
          files: e.dataTransfer.files 
        } 
      };
      onChange(event);
    }
  }, [id, onChange]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileSize(formatFileSize(file.size));
    }
    onChange(e);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    setFileName('');
    setFileSize('');
    fileInputRef.current.value = '';
    // Create a fake event to clear the file
    const event = { 
      target: { 
        name: id, 
        files: null
      } 
    };
    onChange(event);
  };

  return (
    <div className="mb-6">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      
      {description && (
        <p className="text-sm text-gray-500 mb-2">{description}</p>
      )}
      
      <input
        ref={fileInputRef}
        type="file"
        id={id}
        name={id}
        onChange={handleFileInputChange}
        className="hidden"
        accept={accept}
      />
      
      {!fileName ? (
        <div
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`border-2 border-dashed p-6 rounded-lg text-center cursor-pointer transition-colors ${
            isDragging ? 'border-custom-red bg-red-50' : 'border-gray-300 hover:border-gray-400'
          }`}
          onClick={handleBrowseClick}
        >
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-sm text-gray-600">
            Drag & drop your file here, or click to browse
          </p>
          <p className="mt-1 text-xs text-gray-500">
            Supported formats: PDF, DOC, DOCX (Max size: 10MB)
          </p>
        </div>
      ) : (
        <div className="border rounded-lg p-4 bg-gray-50">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10 bg-custom-red rounded-md flex items-center justify-center">
                <Check className="h-6 w-6 text-white" />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900 truncate max-w-xs">
                  {fileName}
                </p>
                <p className="text-xs text-gray-500">{fileSize}</p>
              </div>
            </div>
            <button
              type="button"
              onClick={handleRemoveFile}
              className="text-gray-400 hover:text-red-500"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const TermsModal = ({ show, onClose, onAccept, setTermsRead }) => {
  const [enableAccept, setEnableAccept] = useState(false);
  
  // Use useEffect to enable the button after a reasonable time
  useEffect(() => {
    if (show) {
      // Enable the button after 5 seconds (adjust as needed)
      const timer = setTimeout(() => {
        setEnableAccept(true);
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [show]);
  
  if (!show) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white w-full h-full md:h-[95vh] md:w-[95vw] md:rounded-lg md:mx-auto md:my-auto shadow-2xl flex flex-col">
        <div className="flex justify-between items-center p-4 border-b bg-gray-50">
          <h3 className="text-xl font-semibold">Pitch Process & Terms and Conditions</h3>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 p-1"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="flex-grow overflow-auto p-0">
          <iframe 
            src="/Pitch Process & Terms and Conditions.pdf" 
            className="w-full h-full border-0"
            title="Terms and Conditions"
          />
        </div>
        
        <div className="p-4 border-t bg-gray-50 flex justify-between items-center">
          <div className="text-sm text-gray-600">
            {!enableAccept ? 
              "Please review the terms and conditions..." : 
              "Thank you for reviewing our terms and conditions."}
          </div>
          <button 
            onClick={() => {
              if (setTermsRead) setTermsRead(true);
              onAccept();
              onClose();
            }}
            disabled={!enableAccept}
            className={`px-4 py-2 rounded ${
              enableAccept 
                ? 'bg-custom-red text-white hover:bg-red-600' 
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {enableAccept ? 'I Agree' : 'Please review...'}
          </button>
        </div>
      </div>
    </div>
  );
};

const PitchSubmissionForm = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Applicant Information
    fullName: '',
    organizationName: '',
    email: '',
    phone: '',
    country: '',
    
    // Project Information
    projectTitle: '',
    projectType: '',
    projectDescription: '',
    videoUrl: '',
    
    // New fields to match database schema
    genre: '',
    developmentalStage: '',
    targetAudience: '',
    teamInformation: '',
    marketPotential: '',
    uniqueSellingPoint: '',
    logline: '',
    synopsis: '',
    director: '',
    producer: '',
    budget: '',
    
    // Project Documents
    projectProposalFile: null,
    financialProjectionsFile: null,
    fundingStatus: '',
    distributionDealsFile: null,
    infrastructurePlansFile: null,
    distributionStrategiesFile: null,
    
    // Terms and Conditions
    termsAccepted: false,
    subscribeToNewsletter: true, // Default checked
    termsAcceptedAt: null
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [showReferenceModal, setShowReferenceModal] = useState(false);
  const [referenceCode, setReferenceCode] = useState('');
  const [showLoadForm, setShowLoadForm] = useState(false);
  const [loadReferenceCode, setLoadReferenceCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState('');
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [draftReferenceCode, setDraftReferenceCode] = useState('');
  const [draftError, setDraftError] = useState('');
  const [termsRead, setTermsRead] = useState(false);
  const navigate = useNavigate();

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  // Handle file uploads
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFormData({
        ...formData,
        [name]: files[0]
      });
    }
  };

  // Navigation functions
  const nextStep = () => {
    setCurrentStep(prev => Math.min(prev + 1, 4));
    setError('');
  };

  const prevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
    setError('');
  };

  // Validation function for each step
  const validateStep = (step) => {
    switch (step) {
      case 1: // Applicant Information
        if (!formData.fullName) return 'Please enter your full name';
        if (!formData.organizationName) return 'Please enter your organization name';
        if (!formData.email) return 'Please enter your email address';
        if (!formData.phone) return 'Please enter your phone number';
        if (!formData.country) return 'Please select your country of residence';
        return '';
      
      case 2: // Project Information
        if (!formData.projectTitle) return 'Please enter your project title';
        if (!formData.projectType) return 'Please select a project type';
        if (!formData.projectDescription) return 'Please provide a project description';
        return '';
      
      case 3: // Project Documents
        if (!formData.projectProposalFile) return 'Please upload a detailed project proposal';
        if (!formData.financialProjectionsFile) return 'Please upload financial projections and ROI analysis';
        if (!formData.fundingStatus) return 'Please describe your current funding status';
        return '';
      
      case 4: // Terms and Conditions
        if (!formData.termsAccepted) return 'You must accept the terms and conditions to proceed';
        return '';
      
      default:
        return '';
    }
  };

  // Handle step changes with validation
  const handleStepChange = (direction) => {
    if (direction === 'next') {
      const error = validateStep(currentStep);
      if (error) {
        setError(error);
        return;
      }
      nextStep();
    } else {
      prevStep();
    }
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Instead of checking for the file, validate the text field
    if (!formData.fundingStatus?.trim()) {
      setError('Please describe your current funding status');
      return;
    }
    
    setIsSubmitting(true);
    setError('');
    
    try {
      // Create FormData for file uploads
      const formDataForSubmission = new FormData();
      
      // Add all text fields
      Object.keys(formData).forEach(key => {
        if (typeof formData[key] === 'string' || typeof formData[key] === 'boolean') {
          formDataForSubmission.append(key, formData[key]);
        }
      });
      
      // Add all file fields
      if (formData.projectProposalFile) formDataForSubmission.append('projectProposalFile', formData.projectProposalFile);
      if (formData.financialProjectionsFile) formDataForSubmission.append('financialProjectionsFile', formData.financialProjectionsFile);
      if (formData.fundingStatus) formDataForSubmission.append('fundingStatus', formData.fundingStatus);
      if (formData.distributionDealsFile) formDataForSubmission.append('distributionDealsFile', formData.distributionDealsFile);
      if (formData.infrastructurePlansFile) formDataForSubmission.append('infrastructurePlansFile', formData.infrastructurePlansFile);
      if (formData.distributionStrategiesFile) formDataForSubmission.append('distributionStrategiesFile', formData.distributionStrategiesFile);
      
      // Submit to API
      const response = await apiService.submitPitchApplication(formDataForSubmission);
      
      if (response.data.success) {
        // Update the state to show success message
        setIsSubmitting(false);
        setSuccess('Your pitch application has been submitted successfully! You will now be redirected to register for the AFFF Summit.');
        
        // Redirect to registration page instead of badge purchase
        setTimeout(() => {
          navigate('/register', { 
            state: { 
              fromPitchSubmission: true,
              referenceCode: response.data.data.referenceCode 
            } 
          });
        }, 3000); // Wait 3 seconds before redirecting
      }
    } catch (error) {
      console.error('Error submitting pitch form:', error);
      setError('An error occurred while submitting your application. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Generate reference code for saving progress
  const generateReferenceCode = () => {
    const shortUuid = uuidv4().split('-')[0];
    const dateStr = new Date().toISOString().slice(0,10).replace(/-/g, '');
    const projectIdentifier = formData.projectTitle 
      ? formData.projectTitle.replace(/[^a-zA-Z0-9]/g, '').substring(0, 5).toUpperCase() 
      : `${formData.fullName.split(' ')[0].substring(0, 5)}`.toUpperCase();
      
    return `PITCH-${dateStr}-${projectIdentifier}-${shortUuid}`;
  };

  // Save progress function - updated to use new API
  const saveProgress = async () => {
    if (!formData.email) {
      setError('Please enter your email address to save your progress');
      return;
    }
    
    const code = applicationId || generateReferenceCode();
    
    if (!applicationId) {
      setApplicationId(code);
    }
    
    try {
      // Convert file objects to file names for storage
      const fileData = { ...formData };
      
      // Store file names instead of file objects
      if (fileData.projectProposalFile) fileData.projectProposalFile = fileData.projectProposalFile.name;
      if (fileData.financialProjectionsFile) fileData.financialProjectionsFile = fileData.financialProjectionsFile.name;
      if (fileData.fundingStatus) fileData.fundingStatus = fileData.fundingStatus;
      if (fileData.distributionDealsFile) fileData.distributionDealsFile = fileData.distributionDealsFile?.name;
      if (fileData.infrastructurePlansFile) fileData.infrastructurePlansFile = fileData.infrastructurePlansFile?.name;
      if (fileData.distributionStrategiesFile) fileData.distributionStrategiesFile = fileData.distributionStrategiesFile?.name;
      
      // Use the new draft API endpoint
      await apiService.saveAccreditationDraft({
        type: 'pitch',
        referenceCode: code,
        formData: fileData,
        timestamp: new Date().toISOString()
      });
      
      setReferenceCode(code);
      setShowReferenceModal(true);
    } catch (error) {
      console.error('Error saving progress:', error);
      setError('Failed to save your progress. Please try again.');
    }
  };

  // Load saved application
  const loadSavedApplication = async (referenceCode) => {
    try {
      setIsLoading(true);
      setLoadError('');
      
      const response = await apiService.getDraftByReferenceCode(referenceCode);
      
      if (response.data.success) {
        const { formData: savedData } = response.data.data;
        
        // Set the form data
        setFormData({
          ...formData,
          ...savedData,
          // Reset file objects since they can't be stored/retrieved
          projectProposalFile: null,
          financialProjectionsFile: null,
          fundingStatus: savedData.fundingStatus,
          distributionDealsFile: null,
          infrastructurePlansFile: null,
          distributionStrategiesFile: null,
        });
        
        // Set application ID
        setApplicationId(referenceCode);
        
        setSuccess('Your saved application has been loaded successfully. Please re-upload your files.');
        setShowLoadForm(false);
      } else {
        setLoadError('Failed to load your saved application. Please try again.');
      }
    } catch (error) {
      console.error('Error loading saved application:', error);
      setLoadError('Failed to load your saved application. The reference code may be invalid.');
    } finally {
      setIsLoading(false);
    }
  };

  // Render reference modal
  const renderReferenceModal = () => {
    if (!showReferenceModal) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Your Application Reference</h3>
          <p className="mb-3">Your application has been saved. Please use this reference code to resume your application later:</p>
          
          <div className="bg-gray-100 p-3 rounded border text-center font-mono font-bold text-lg mb-4">
            {referenceCode}
          </div>
          
          <p className="text-sm mb-4">
            <strong>Important:</strong> Please save this reference code. You will need it to resume your application.
          </p>
          
          <div className="flex justify-between">
            <button
              onClick={() => {navigator.clipboard.writeText(referenceCode); alert('Reference code copied to clipboard!')}}
              className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Copy Code
            </button>
            
            <button
              onClick={() => setShowReferenceModal(false)}
              className="px-4 py-2 bg-custom-red text-white rounded-md hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Render load application form
  const renderLoadApplicationForm = () => {
    if (!showLoadForm) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Load Saved Application</h3>
          <p className="mb-3">Enter your reference code to continue a saved application:</p>
          
          <div className="mb-4">
            <input 
              type="text" 
              value={loadReferenceCode}
              onChange={(e) => setLoadReferenceCode(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter reference code"
            />
          </div>
          
          {loadError && (
            <div className="mb-4 p-2 bg-red-50 text-red-700 border-l-4 border-red-500">
              {loadError}
            </div>
          )}
          
          <div className="flex justify-between">
            <button
              onClick={() => setShowLoadForm(false)}
              className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Cancel
            </button>
            
            <button
              onClick={() => loadSavedApplication(loadReferenceCode)}
              className="px-4 py-2 bg-custom-red text-white rounded-md hover:bg-red-600"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Load Application'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Render form steps
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-bold">Applicant Information</h3>
            
            <div>
              <label className="block mb-1 font-medium">Full Name *</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            
            <div>
              <label className="block mb-1 font-medium">Organization Name *</label>
              <input
                type="text"
                name="organizationName"
                value={formData.organizationName}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            
            <div>
              <label className="block mb-1 font-medium">Email Address *</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            
            <div>
              <label className="block mb-1 font-medium">Phone Number *</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            
            <div>
              <label className="block mb-1 font-medium">Country of Residence *</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                required
              >
                <option value="">Select Country</option>
                {countries.map(country => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
            </div>
          </div>
        );
      
      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-bold">Project Information</h3>
            
            <div>
              <label className="block mb-1 font-medium">Project Title *</label>
              <input
                type="text"
                name="projectTitle"
                value={formData.projectTitle}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            
            <div>
              <label className="block mb-1 font-medium">Project Type *</label>
              <select
                name="projectType"
                value={formData.projectType}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                required
              >
                <option value="">Select Project Type</option>
                <option value="Film Production">Film Production</option>
                <option value="Film Infrastructure">Film Infrastructure</option>
                <option value="Film Distribution">Film Distribution</option>
              </select>
            </div>
            
            <div>
              <label className="block mb-1 font-medium">Project Description (200 words) *</label>
              <textarea
                name="projectDescription"
                value={formData.projectDescription}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                rows={6}
                maxLength={2000}
                required
              ></textarea>
              <p className="text-xs text-gray-500 mt-1">
                {formData.projectDescription.length}/2000 characters
              </p>
            </div>
            
            <div>
              <label className="block mb-1 font-medium">Brief 5-Minute Video About Project (URL)</label>
              <input
                type="text"
                name="videoUrl"
                value={formData.videoUrl}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-md"
                placeholder="YouTube or Vimeo URL (optional but recommended)"
              />
              <p className="text-xs text-gray-500 mt-1">
                Submitting a video increases your chances of selection
              </p>
            </div>
          </div>
        );
      
      case 3:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-bold">Project Documents Upload</h3>
            
            <FileDropZone
              label="Detailed Project Proposal"
              id="projectProposalFile"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx"
              required
              description="Include concept, storyline, target audience, and creative vision"
            />

            <FileDropZone
              label="Financial Projections and ROI Analysis"
              id="financialProjectionsFile"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.xls,.xlsx"
              required
              description="Detailed budget, expected returns, and financial planning"
            />

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Current Funding Status and Investment Needs
                <span className="text-red-500">*</span>
              </label>
              <textarea
                name="fundingStatus"
                value={formData.fundingStatus || ''}
                onChange={handleInputChange}
                rows={4}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm 
                          focus:border-custom-red focus:ring focus:ring-custom-red focus:ring-opacity-20 
                          sm:text-sm p-3 resize-none hover:border-gray-400 transition duration-150"
                placeholder="Describe your current funding status, including any secured investments and your remaining investment needs."
                required
              ></textarea>
              {error && (
                <p className="mt-1 text-sm text-red-600">{error}</p>
              )}
            </div>

            <FileDropZone
              label="Pre-Existing Distribution Deals or Partnerships (if applicable)"
              id="distributionDealsFile"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx"
              description="Any existing distribution agreements or partnerships"
            />

            {formData.projectType === 'Film Infrastructure' && (
              <FileDropZone
                label="Infrastructure Development Plans"
                id="infrastructurePlansFile"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx"
                description="Detailed plans for infrastructure development projects"
              />
            )}
            
            {formData.projectType === 'Film Distribution' && (
              <FileDropZone
                label="Distribution Strategies and Market Outreach Plans"
                id="distributionStrategiesFile"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx"
                description="Marketing, distribution, and outreach strategies"
              />
            )}
          </div>
        );
      
      case 4:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-bold">Terms and Conditions Agreement</h3>
            
            <div className="bg-gray-50 p-4 rounded-md">
              <p className="mb-4">
                By checking the box below, you confirm that you have read and agree to the AFFF Pitch Process & Terms and Conditions. Upon submission, you will be redirected to register for the AFFF Summit and acquire your AFFF Premium Elite Plus Badge (mandatory for participation).
              </p>
              
              <div className="flex items-start mt-4">
                <input
                  type="checkbox"
                  id="termsAccepted"
                  name="termsAccepted"
                  checked={formData.termsAccepted || false}
                  onChange={(e) => {
                    if (e.target.checked && !termsRead) {
                      // If user is trying to check without reading terms
                      e.preventDefault();
                      setShowTermsModal(true);
                      return;
                    }
                    handleInputChange({
                      target: {
                        name: 'termsAccepted',
                        value: e.target.checked
                      }
                    });
                  }}
                  className="h-4 w-4 mt-1 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  required
                />
                <label htmlFor="termsAccepted" className="ml-2 block text-sm text-gray-700">
                  I have read and agree to the <button 
                    type="button" 
                    onClick={() => setShowTermsModal(true)} 
                    className="text-custom-red underline"
                  >
                    Terms and Conditions
                  </button>.
                  {!termsRead && formData.termsAccepted && (
                    <span className="text-red-500 ml-1 text-xs">Please read the terms first</span>
                  )}
                </label>
              </div>
            </div>
            
            <div className="bg-yellow-50 p-4 rounded-md border-l-4 border-yellow-400">
              <h4 className="font-bold">AFFF Summit Badge Information</h4>
              <p className="mt-2">
                Upon submission, you will be redirected to register for the AFFF Summit and acquire your AFFF Premium Elite Plus Badge (This is Mandatory).
              </p>
              <p className="mt-2">
                The badge grants you a range of exclusive benefits, including pre-event privileges, event-day advantages, and post-event perks.
              </p>
            </div>
            
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="subscribeToNewsletter"
                  checked={formData.subscribeToNewsletter}
                  onChange={(e) => handleInputChange({
                    target: {
                      name: 'subscribeToNewsletter',
                      value: e.target.checked
                    }
                  })}
                  className="mr-2"
                />
                <span>Subscribe to our newsletter for updates on film funding opportunities and industry events</span>
              </label>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  // Function to show a simple notification since we don't have toast
  const showNotification = (message, isError = false) => {
    if (isError) {
      console.error(message);
      alert(`Error: ${message}`);
    } else {
      console.log(message);
      alert(message);
    }
  };

  // Add draft retrieval function
  const retrieveDraft = async (referenceCode) => {
    setIsLoading(true);
    try {
      // Make sure we're using the correct API endpoint
      const response = await apiService.getPitchDraft(referenceCode.trim());
      
      if (response.data && response.data.success) {
        // Log the retrieved data for debugging
        console.log('Retrieved draft data:', response.data);
        
        // Update form with retrieved data
        const draftData = response.data.data;
        setFormData(prevState => ({
          ...prevState,
          ...draftData
        }));
        
        // Success notification
        showNotification('Draft loaded successfully!');
        setDraftError('');
      } else {
        // Handle case where API returned success:false
        setDraftError(response.data?.message || 'Failed to retrieve draft with this reference code');
        showNotification('Failed to load draft', true);
      }
    } catch (error) {
      console.error('Error retrieving draft:', error);
      
      // More detailed error handling
      if (error.response) {
        if (error.response.status === 404) {
          setDraftError('No draft found with this reference code. Please check and try again.');
        } else {
          setDraftError(`Server error: ${error.response.data?.message || error.message}`);
        }
      } else if (error.request) {
        setDraftError('Network error. Please check your connection and try again.');
      } else {
        setDraftError(`Error: ${error.message}`);
      }
      
      showNotification('Failed to load draft', true);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle draft retrieval
  const handleRetrieveDraft = (e) => {
    e.preventDefault();
    
    if (!draftReferenceCode || draftReferenceCode.trim() === '') {
      setDraftError('Please enter a reference code');
      return;
    }
    
    // Log the reference code that's being used
    console.log('Attempting to retrieve draft with code:', draftReferenceCode);
    
    retrieveDraft(draftReferenceCode);
  };

  return (
    <>
      <BaseForm onClose={onClose} title="AFFF Pitch Opportunities Registration">
        <form onSubmit={handleSubmit} className="p-6">
          {/* Progress bar */}
          <div className="mb-6">
            <div className="flex justify-between mb-2">
              {[1, 2, 3, 4].map(step => (
                <div 
                  key={step}
                  className={`text-xs font-medium ${
                    currentStep >= step ? 'text-custom-red' : 'text-gray-400'
                  }`}
                >
                  Step {step}
                </div>
              ))}
            </div>
            <div className="h-2 bg-gray-200 rounded-full">
              <div 
                className="h-2 bg-custom-red rounded-full transition-all duration-300"
                style={{ width: `${(currentStep / 4) * 100}%` }}
              ></div>
            </div>
          </div>
          
          {/* Form step content */}
          {renderStep()}
          
          {/* Error message */}
          {error && (
            <div className="mt-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
              <p>{error}</p>
            </div>
          )}
          
          {/* Success message */}
          {success && (
            <div className="mt-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
              <p>{success}</p>
            </div>
          )}
          
          {/* Resume application button */}
          <div className="mt-4 mb-6">
            <button
              type="button"
              onClick={() => setShowLoadForm(true)}
              className="text-custom-red hover:underline text-sm"
            >
              Already started an application? Click here to resume
            </button>
          </div>
          
          {/* Navigation buttons */}
          <div className="mt-8 flex justify-between">
            {/* Left side button - Previous or Cancel */}
            {currentStep > 1 ? (
              <button
                type="button"
                onClick={() => handleStepChange('prev')}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
            ) : (
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
            )}
            
            {/* Right side buttons - grouped Save and Next/Submit */}
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={saveProgress}
                className="px-4 py-2 bg-[#212121] text-white rounded-md hover:bg-gray-800"
              >
                Save Progress
              </button>
              
              {currentStep < 4 ? (
                <button
                  type="button"
                  onClick={() => handleStepChange('next')}
                  className="px-6 py-2 bg-custom-red text-white rounded-md hover:bg-red-600"
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-6 py-2 bg-custom-red text-white rounded-md ${
                    isSubmitting ? 'opacity-75 cursor-not-allowed' : 'hover:bg-red-600'
                  }`}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit Application'}
                </button>
              )}
            </div>
          </div>
        </form>
      </BaseForm>
      
      {/* Render modals */}
      {renderReferenceModal()}
      {renderLoadApplicationForm()}
      
      {/* Terms & Conditions Modal */}
      {showTermsModal && (
        <TermsModal 
          show={showTermsModal}
          onClose={() => setShowTermsModal(false)}
          setTermsRead={setTermsRead}
          onAccept={() => {
            // Update the checkbox state
            setFormData(prev => ({
              ...prev,
              termsAccepted: true
            }));
          }}
        />
      )}
    </>
  );
};

export default PitchSubmissionForm;