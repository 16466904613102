import React, { useEffect } from 'react';

const BaseForm = ({ title, subtitle, children, onClose, isLoading }) => {
  // Prevent scrolling of background when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-4 bg-black bg-opacity-40">
      <div className="bg-white rounded-lg font-lexend shadow-2xl w-full max-w-4xl relative" style={{boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.3), 0 5px 10px -5px rgba(0, 0, 0, 0.2)'}}>
        {/* Header section with title */}
        <div className="bg-custom-red text-white p-5 relative flex justify-between items-center rounded-t-lg">
          <h2 className="text-xl font-bold font-lexend">{title}</h2>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-200 focus:outline-none"
            aria-label="Close"
          >
            <span className="text-2xl">&times;</span>
          </button>
        </div>
        
        {/* Main content area with fixed height and scrollable content if needed */}
        <div className="p-6 max-h-[70vh] overflow-y-auto">
          {subtitle && <p className="text-gray-600 mb-6">{subtitle}</p>}
          {children}
        </div>
        
        {/* Loading overlay */}
        {isLoading && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-red"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BaseForm;
