// API Configuration Settings
export const API_SETTINGS = {
  // Base URLs - ensuring proper API prefix for both environments
  // BASE_URL: `${import.meta.env.VITE_API_URL || (process.env.NODE_ENV === 'production' 
  //   ? 'https://api.africafilmfinanceforum.com'
  //   : 'http://localhost:5000')}/api`,
  BASE_URL: 'https://api.africafilmfinanceforum.com/api',
  
  // Endpoints - without /api prefix since it's in the BASE_URL
  ENDPOINTS: {
    REGISTER: '/register',
    SUBSCRIBE: '/subscribe',
    FILM_SCREENING: '/film-screening',
    CONTACT: '/contact',
    PAYMENT: '/payment',
    FINANCIER_ACCREDITATION: '/financier-accreditation',
    INDUSTRY_ACCREDITATION: '/industry-accreditation',
    EXHIBITION: '/exhibition',
    ACCREDITATION_DRAFT: '/accreditation-draft',
    PITCH_SUBMISSION: '/pitch-submission',
    PITCH_DRAFT: '/pitch-draft'
  },
  
  // Request Configuration
  REQUEST_CONFIG: {
    TIMEOUT: 30000,
    RETRY_ATTEMPTS: 3,
    RETRY_DELAY: 2000
  },
  
  // Headers
  HEADERS: {
    DEFAULT: {
      'Content-Type': 'application/json'
    },
    MULTIPART: {
      'Content-Type': 'multipart/form-data'
    }
  },
  
  // Email marketing settings
  EMAIL_MARKETING: {
    ECOMMS_LIST_ID: 5 // Added as requested
  }
};

// Helper function to construct full API URLs
export const getApiUrl = (endpoint) => {
  // Make sure we handle endpoints that already start with a slash
  const formattedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
  return `${API_SETTINGS.BASE_URL}${formattedEndpoint}`;
};

// Export a simple API URL for direct use
export const API_URL = import.meta.env.VITE_API_URL || (process.env.NODE_ENV === 'production' 
  ? 'https://api.africafilmfinanceforum/api'
  : 'http://localhost:5000'); 