import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import MasterClassEnrollmentForm from '../component/MasterClassEnrollmentForm';
import masterClassBgImage from "../assets/master-class-bg.jpg"; // Make sure this image exists

const MasterClassPage = () => {
  const [showEnrollmentForm, setShowEnrollmentForm] = useState(false);

  return (
    <div className="relative">
      <PageHeader 
        title="MASTER CLASS" 
        description="Exclusive tutoring from industry experts in film production, marketing, and distribution."
        backgroundImage={masterClassBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">MASTER CLASS</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              AFFF Masterclass offers exclusive tutoring from seasoned professionals in the film and finance
              industries, covering all aspects of movie production, marketing, and distribution to ensure a return on
              investment (ROI). This opportunity is specifically available to Masterclass badge holders.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              Participants in the AFFF Masterclass gain a holistic understanding of film production, marketing, and
              distribution, benefiting both filmmakers and finance professionals alike. Filmmakers delve into script
              development, casting, production design, cinematography, editing, and post-production processes,
              equipping them with the skills needed to create successful films that resonate with audiences and
              generate revenue.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              For finance professionals, the Masterclass offers insights into the diverse landscape of distribution
              channels, including traditional theatrical releases, digital platforms, streaming services, and international
              markets. This knowledge enables them to make informed investment decisions, understand the revenue
              potential of different distribution models, and explore innovative financing options that maximize
              returns on investment (ROI) within the film industry.
            </p>
            <div className="bg-[#f7f4f0] p-4 rounded-lg mb-6">
              <p className="font-bold">Registration Deadline: September 1st, 2025</p>
            </div>
            <button 
              onClick={() => setShowEnrollmentForm(true)}
              className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300"
            >
              ENROLL FOR MASTER CLASS
            </button>
          </section>
        </div>
      </main>
      <Footer />

      <AnimatePresence>
        {showEnrollmentForm && (
          <MasterClassEnrollmentForm onClose={() => setShowEnrollmentForm(false)} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default MasterClassPage;