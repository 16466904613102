// FinancierAccreditationForm.jsx
import React, { useState, useEffect } from 'react';
import BaseForm from './BaseForm';
import apiService from '../services/apiService';
import { v4 as uuidv4 } from 'uuid';
import countries from '../data/countries'; // Assuming you have this file

const FinancierAccreditationForm = ({ onClose, initialFormData }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    // Personal Information
    fullName: '',
    email: '',
    phone: '',
    country: '',
    position: '',
    
    // Company Information
    companyName: '',
    companyField: '',
    companyLocation: '',
    
    // Finance Experience
    isFinanceIndustry: 'Yes',
    yearsExperience: '',
    financeIndustryType: '',
    otherIndustryType: '',
    otherIndustry: '',
    
    // Film Investment Experience
    hasInvestedInFilm: 'No',
    filmInvestmentTypes: {
      production: false,
      distribution: false,
      infrastructure: false,
      coproductions: false,
      digital: false,
      funds: false,
      other: false
    },
    otherFilmInvestmentType: '',
    investmentSize: '',
    investmentInterests: {
      grant: false,
      debt: false,
      equity: false,
      mixed: false,
      other: false
    },
    otherInvestmentInterest: '',
    subscribeToNewsletter: true, // Default checked
    referenceCode: null // Add this field
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [showReferenceModal, setShowReferenceModal] = useState(false);
  const [referenceCode, setReferenceCode] = useState('');
  const [showLoadForm, setShowLoadForm] = useState(false);
  const [loadReferenceCode, setLoadReferenceCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState('');
  const [showReferenceCode, setShowReferenceCode] = useState(false);
  const [savingProgress, setSavingProgress] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [saveSuccess, setSaveSuccess] = useState('');

  useEffect(() => {
    // Check for resumed form data in sessionStorage
    const resumedData = sessionStorage.getItem('resumedFormData');
    if (resumedData) {
      try {
        const parsedData = JSON.parse(resumedData);
        setFormData(prev => ({
          ...prev,
          ...parsedData
        }));
        
        // If there's a reference code, show it
        if (parsedData.referenceCode) {
          setShowReferenceCode(true);
        }
        
        // Clear the sessionStorage so it doesn't affect other forms
        sessionStorage.removeItem('resumedFormData');
      } catch (error) {
        console.error('Error parsing resumed form data:', error);
      }
    }
  }, []);

  useEffect(() => {
    // If formData is provided from parent (resume case), use it to initialize form
    if (initialFormData && Object.keys(initialFormData).length > 0) {
      console.log('Initializing form with data:', initialFormData);
      setFormData(prev => ({
        ...prev,
        ...initialFormData
      }));
    }
  }, [initialFormData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.includes('.')) {
      // Handle nested objects (checkboxes)
      const [parent, child] = name.split('.');
      setFormData({
        ...formData,
        [parent]: {
          ...formData[parent],
          [child]: type === 'checkbox' ? checked : value
        }
      });
    } else {
      // Handle regular inputs
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateStep = (step) => {
    setError('');
    
    // Validation logic for each step
    switch(step) {
      case 1: // Personal Information
        if (!formData.fullName.trim()) return "Full name is required";
        if (!formData.email.trim()) return "Email is required";
        if (!/^\S+@\S+\.\S+$/.test(formData.email)) return "Invalid email format";
        if (!formData.phone.trim()) return "Phone number is required";
        if (!formData.country.trim()) return "Country is required";
        if (!formData.position.trim()) return "Position/Role is required";
        break;
        
      case 2: // Company Information
        if (!formData.companyName.trim()) return "Company name is required";
        if (!formData.companyLocation.trim()) return "Company location is required";
        break;
        
      case 3: // Finance Experience
        if (formData.isFinanceIndustry === 'Yes') {
          if (!formData.yearsExperience) return "Years of experience is required";
          if (!formData.financeIndustryType) return "Please specify your finance industry type";
          if (formData.financeIndustryType === 'Other' && !formData.otherIndustryType.trim()) 
            return "Please specify your other finance industry type";
        } else {
          if (!formData.otherIndustry.trim()) return "Please specify your industry";
        }
        
        // Film Investment validation
        if (formData.hasInvestedInFilm === 'Yes') {
          // Check if at least one investment type is selected
          const hasInvestmentType = Object.values(formData.filmInvestmentTypes).some(value => value === true);
          if (!hasInvestmentType) return "Please select at least one film investment type";
          
          // Check if other is specified
          if (formData.filmInvestmentTypes.other && !formData.otherFilmInvestmentType.trim())
            return "Please specify your other film investment type";
            
          if (!formData.investmentSize) return "Please specify your typical investment size";
          
          // Check if at least one investment interest is selected
          const hasInvestmentInterest = Object.values(formData.investmentInterests).some(value => value === true);
          if (!hasInvestmentInterest) return "Please select at least one investment interest";
          
          // Check if other is specified
          if (formData.investmentInterests.other && !formData.otherInvestmentInterest.trim())
            return "Please specify your other investment interest";
        }
        break;
    }
    
    return null; // No errors
  };

  const handleStepChange = (direction) => {
    if (direction === 'next') {
      const error = validateStep(currentStep);
      if (error) {
        setError(error);
        return;
      }
      nextStep();
    } else {
      prevStep();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    
    try {
      // Make sure formData is a regular object with proper field names
      const submissionData = {
        contactName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        country: formData.country,
        position: formData.position,
        companyName: formData.companyName,
        financierName: formData.financierName || formData.fullName,
        companyField: formData.companyField,
        companyLocation: formData.companyLocation,
        isFinanceIndustry: formData.isFinanceIndustry,
        yearsExperience: formData.yearsExperience,
        financeIndustryType: formData.financeIndustryType,
        otherIndustryType: formData.otherIndustryType,
        otherIndustry: formData.otherIndustry,
        hasInvestedInFilm: formData.hasInvestedInFilm,
        filmInvestmentTypes: formData.filmInvestmentTypes,
        otherFilmInvestmentType: formData.otherFilmInvestmentType,
        investmentSize: formData.investmentSize,
        investmentInterests: formData.investmentInterests,
        otherInvestmentInterest: formData.otherInvestmentInterest,
        subscribeToNewsletter: formData.subscribeToNewsletter,
        listId: formData.subscribeToNewsletter ? 5 : undefined
      };
      
      const response = await apiService.submitFinancierAccreditation(submissionData);
      
      if (response.data && response.data.success) {
        // Store reference code for badge purchase
        setReferenceCode(response.data.data.referenceCode);
        
        // Show success and next steps
        setSuccess('Application submitted successfully!');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Submission error:', error);
      setIsSubmitting(false);
      
      // Show error message
      setError(error.response?.data?.message || 'Failed to submit. Please try again.');
    }
  };

  const generateReferenceCode = () => {
    const shortUuid = uuidv4().split('-')[0];
    const dateStr = new Date().toISOString().slice(0,10).replace(/-/g, '');
    const companyIdentifier = formData.companyName 
      ? formData.companyName.replace(/[^a-zA-Z0-9]/g, '').substring(0, 5).toUpperCase() 
      : `${formData.fullName.split(' ')[0].substring(0, 5)}`.toUpperCase();
      
    return `FIN-${dateStr}-${companyIdentifier}-${shortUuid}`;
  };

  const saveProgress = async () => {
    try {
      setSavingProgress(true);
      setSaveError('');
      
      // Save as draft using the consistent API endpoint
      const draftData = {
        type: 'financier',
        referenceCode: formData.referenceCode || null,
        formData: { ...formData },
        timestamp: new Date().toISOString(),
        sendEmailNotification: true,
        userEmail: formData.email || null,
        listId: formData.subscribeToNewsletter ? 5 : undefined
      };
      
      const response = await apiService.saveAccreditationDraft(draftData);
      
      // Update reference code if successful
      if (response.data && response.data.data && response.data.data.referenceCode) {
        setFormData(prev => ({
          ...prev,
          referenceCode: response.data.data.referenceCode
        }));
        
        // Show reference code modal
        setShowReferenceModal(true);
        setSaveSuccess('Progress saved successfully!');
        
        // Hide success message after 5 seconds
        setTimeout(() => {
          setSaveSuccess('');
        }, 5000);
      }
    } catch (error) {
      console.error('Error saving progress:', error);
      setSaveError('Failed to save progress. Please try again.');
      
      // Hide error message after 5 seconds
      setTimeout(() => {
        setSaveError('');
      }, 5000);
    } finally {
      setSavingProgress(false);
    }
  };

  const loadSavedApplication = async (referenceCode) => {
    try {
      setIsLoading(true);
      setLoadError('');
      
      const response = await apiService.getDraftByReferenceCode(referenceCode);
      
      if (response.data.success) {
        const { formData: savedData } = response.data.data;
        
        // Set the form data
        setFormData(savedData);
        
        // Set application ID
        setApplicationId(referenceCode);
        
        setSuccess('Your saved application has been loaded successfully.');
        setShowLoadForm(false);
      } else {
        setLoadError('Failed to load your saved application. Please try again.');
      }
    } catch (error) {
      console.error('Error loading saved application:', error);
      setLoadError('Failed to load your saved application. The reference code may be invalid.');
    } finally {
      setIsLoading(false);
    }
  };

  const ReferenceCodeModal = () => {
    if (!showReferenceModal) return null;
    
    const copyReferenceCode = () => {
      navigator.clipboard.writeText(formData.referenceCode)
        .then(() => {
          alert('Reference code copied to clipboard!');
        })
        .catch(err => {
          console.error('Failed to copy reference code:', err);
          // Fallback for browsers that don't support clipboard API
          const textarea = document.createElement('textarea');
          textarea.value = formData.referenceCode;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          alert('Reference code copied to clipboard!');
        });
    };
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full relative">
          {/* Close button positioned outside the main content, in the top-right corner */}
          <button
            onClick={() => setShowReferenceModal(false)}
            className="absolute -top-4 -right-4 w-10 h-10 flex items-center justify-center bg-gray-800 text-white rounded-full shadow-md hover:bg-gray-900 transition-all focus:outline-none"
            type="button"
            aria-label="Close"
          >
            <span className="text-xl font-bold">&times;</span>
          </button>
          
          <h3 className="text-xl font-bold mb-4">Your Reference Code</h3>
          <p className="mb-4">Keep this code safe - you'll need it to resume your application later:</p>
          
          <div className="bg-gray-100 p-3 rounded-md mb-4">
            <p className="text-center font-mono text-lg select-all">{formData.referenceCode}</p>
          </div>
          
          <div className="flex justify-center">
            <button
              onClick={copyReferenceCode}
              className="px-6 py-2 bg-custom-red text-white rounded-md hover:bg-red-600 shadow-md hover:shadow-lg transition-all"
              type="button"
            >
              Copy Code
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderLoadApplicationForm = () => {
    if (!showLoadForm) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Load Saved Application</h3>
          <p className="mb-3">Enter your reference code to continue a saved application:</p>
          
          <div className="mb-4">
            <input 
              type="text" 
              value={loadReferenceCode}
              onChange={(e) => setLoadReferenceCode(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter reference code"
            />
          </div>
          
          {loadError && (
            <div className="mb-4 p-2 bg-red-50 text-red-700 border-l-4 border-red-500">
              {loadError}
            </div>
          )}
          
          <div className="flex justify-between">
            <button
              onClick={() => setShowLoadForm(false)}
              className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Cancel
            </button>
            
            <button
              onClick={() => loadSavedApplication(loadReferenceCode)}
              className="px-4 py-2 bg-custom-red text-white rounded-md hover:bg-red-600"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Load Application'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-bold mb-4">Personal Information</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Full Name *</label>
              <input 
                type="text" 
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your full name"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Email Address *</label>
              <input 
                type="email" 
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your email address"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number *</label>
              <input 
                type="tel" 
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your phone number"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Country of Residence *</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                <option value="">Select your country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Position/Role *</label>
              <input 
                type="text" 
                name="position"
                value={formData.position}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your position or role"
                required
              />
            </div>
          </div>
        );
        
      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-bold mb-4">Company Information</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Company/Organization Name *</label>
              <input 
                type="text" 
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your company or organization name"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Company Field (if applicable)</label>
              <input 
                type="text" 
                name="companyField"
                value={formData.companyField}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your company's field"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Company Location (City/Country) *</label>
              <input 
                type="text" 
                name="companyLocation"
                value={formData.companyLocation}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter your company's location (city, country)"
                required
              />
            </div>
          </div>
        );
        
      case 3:
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-bold mb-4">Finance & Investment Experience</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Are you in the investment and finance industry? *</label>
              <select
                name="isFinanceIndustry"
                value={formData.isFinanceIndustry}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            
            {formData.isFinanceIndustry === 'Yes' && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">How long have you been involved in the investment and finance industry? *</label>
                  <select
                    name="yearsExperience"
                    value={formData.yearsExperience}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  >
                    <option value="">Select years</option>
                    <option value="Less than 1 year">Less than 1 year</option>
                    <option value="1-3 years">1-3 years</option>
                    <option value="3-5 years">3-5 years</option>
                    <option value="More than 5 years">More than 5 years</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Kindly specify the type of investment and finance industry in which you operate *</label>
                  <select
                    name="financeIndustryType"
                    value={formData.financeIndustryType}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  >
                    <option value="">Select industry type</option>
                    <option value="Venture Capital">Venture Capital</option>
                    <option value="Angel Investor">Angel Investor</option>
                    <option value="Private Investor">Private Investor</option>
                    <option value="Hedge Fund">Hedge Fund</option>
                    <option value="Bank">Bank</option>
                    <option value="Other">Other (Please specify)</option>
                  </select>
                </div>
                
                {formData.financeIndustryType === 'Other' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Please specify your finance industry type *</label>
                    <input 
                      type="text" 
                      name="otherIndustryType"
                      value={formData.otherIndustryType}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      placeholder="Enter your industry type"
                      required
                    />
                  </div>
                )}
              </>
            )}
            
            {formData.isFinanceIndustry === 'No' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">What industry do you operate in? *</label>
                <input 
                  type="text" 
                  name="otherIndustry"
                  value={formData.otherIndustry}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter your industry"
                  required
                />
              </div>
            )}
            
            <hr className="border-gray-200" />
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Have you ever invested in a Film Project? *</label>
              <select
                name="hasInvestedInFilm"
                value={formData.hasInvestedInFilm}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            
            {formData.hasInvestedInFilm === 'Yes' && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">What Kind of Film Project? *</label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="production"
                        name="filmInvestmentTypes.production"
                        checked={formData.filmInvestmentTypes.production}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="production" className="ml-2 text-sm text-gray-700">Film Production Financing</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="distribution"
                        name="filmInvestmentTypes.distribution"
                        checked={formData.filmInvestmentTypes.distribution}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="distribution" className="ml-2 text-sm text-gray-700">Film Distribution Financing</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="infrastructure"
                        name="filmInvestmentTypes.infrastructure"
                        checked={formData.filmInvestmentTypes.infrastructure}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="infrastructure" className="ml-2 text-sm text-gray-700">Film Infrastructure Investment</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="coproductions"
                        name="filmInvestmentTypes.coproductions"
                        checked={formData.filmInvestmentTypes.coproductions}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="coproductions" className="ml-2 text-sm text-gray-700">Co-productions</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="digital"
                        name="filmInvestmentTypes.digital"
                        checked={formData.filmInvestmentTypes.digital}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="digital" className="ml-2 text-sm text-gray-700">Digital film infrastructure Investment</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="funds"
                        name="filmInvestmentTypes.funds"
                        checked={formData.filmInvestmentTypes.funds}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="funds" className="ml-2 text-sm text-gray-700">Film Funds</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="otherFilmInvestment"
                        name="filmInvestmentTypes.other"
                        checked={formData.filmInvestmentTypes.other}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="otherFilmInvestment" className="ml-2 text-sm text-gray-700">Other (Please specify)</label>
                    </div>
                  </div>
                </div>
                
                {formData.filmInvestmentTypes.other && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Please specify your film investment type *</label>
                    <input 
                      type="text" 
                      name="otherFilmInvestmentType"
                      value={formData.otherFilmInvestmentType}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      placeholder="Enter your film investment type"
                      required
                    />
                  </div>
                )}
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">What is the typical size of investment you could provide for a film project? *</label>
                  <select
                    name="investmentSize"
                    value={formData.investmentSize}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  >
                    <option value="">Select investment size</option>
                    <option value="₦10 million – ₦25 million">₦10 million – ₦25 million (Approx. $13,000 – $32,500)</option>
                    <option value="₦30 million – ₦50 million">₦30 million – ₦50 million (Approx. $39,000 – $65,000)</option>
                    <option value="₦100 million – ₦200 million">₦100 million – ₦200 million (Approx. $130,000 – $260,000)</option>
                    <option value="₦300 million and more">₦300 million and more (Approx. $390,000 and more)</option>
                    <option value="Still considering">Still considering (TBD)</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Kindly indicate the type of investments you will be interested in: *</label>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="grant"
                        name="investmentInterests.grant"
                        checked={formData.investmentInterests.grant}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="grant" className="ml-2 text-sm text-gray-700">Grant</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="debt"
                        name="investmentInterests.debt"
                        checked={formData.investmentInterests.debt}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="debt" className="ml-2 text-sm text-gray-700">Debt Financing</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="equity"
                        name="investmentInterests.equity"
                        checked={formData.investmentInterests.equity}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="equity" className="ml-2 text-sm text-gray-700">Equity</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="mixed"
                        name="investmentInterests.mixed"
                        checked={formData.investmentInterests.mixed}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="mixed" className="ml-2 text-sm text-gray-700">Mixed (Debt Financing and Equity)</label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="checkbox" 
                        id="otherInterest"
                        name="investmentInterests.other"
                        checked={formData.investmentInterests.other}
                        onChange={handleChange}
                        className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                      />
                      <label htmlFor="otherInterest" className="ml-2 text-sm text-gray-700">Other (Please specify)</label>
                    </div>
                  </div>
                </div>
                
                {formData.investmentInterests.other && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Please specify your investment interest *</label>
                    <input 
                      type="text" 
                      name="otherInvestmentInterest"
                      value={formData.otherInvestmentInterest}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      placeholder="Enter your investment interest"
                      required
                    />
                  </div>
                )}
              </>
            )}
          </div>
        );
        
      default:
        return null;
    }
  };

  return (
    <>
      <BaseForm onClose={onClose} title="Financier Accreditation Application">
        <form onSubmit={handleSubmit} className="p-6">
          {/* Progress bar */}
          <div className="mb-6">
            <div className="flex justify-between mb-2">
              {[1, 2, 3].map(step => (
                <div 
                  key={step}
                  className={`text-xs font-medium ${
                    currentStep >= step ? 'text-custom-red' : 'text-gray-400'
                  }`}
                >
                  Step {step}
                </div>
              ))}
            </div>
            <div className="h-2 bg-gray-200 rounded-full">
              <div 
                className="h-2 bg-custom-red rounded-full transition-all duration-300"
                style={{ width: `${(currentStep / 3) * 100}%` }}
              ></div>
            </div>
          </div>
          
          {/* Form step content */}
          {renderStep()}
          
          {/* Error message */}
          {error && (
            <div className="mt-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
              <p>{error}</p>
            </div>
          )}
          
          {/* Success message */}
          {success && (
            <div className="mt-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
              <p>{success}</p>
            </div>
          )}
          
          {/* Resume application button */}
          <div className="mt-4 mb-6">
            <button
              type="button"
              onClick={() => setShowLoadForm(true)}
              className="text-custom-red hover:underline text-sm"
            >
              Already started an application? Click here to resume
            </button>
          </div>
          
          {/* Newsletter opt-in */}
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="subscribeToNewsletter"
                checked={formData.subscribeToNewsletter}
                onChange={(e) => handleChange({
                  target: {
                    name: 'subscribeToNewsletter',
                    value: e.target.checked
                  }
                })}
                className="mr-2"
              />
              <span>Subscribe to our newsletter for updates on film financing and investment opportunities</span>
            </label>
          </div>
          
          {/* Navigation buttons */}
          <div className="mt-8 flex justify-between">
            {/* Left side button - Previous or Cancel */}
            {currentStep > 1 ? (
              <button
                type="button"
                onClick={() => handleStepChange('prev')}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 shadow-md hover:shadow-lg transition-all"
              >
                Previous
              </button>
            ) : (
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 shadow-md hover:shadow-lg transition-all"
              >
                Cancel
              </button>
            )}
            
            {/* Right side buttons - grouped Save and Next/Submit */}
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={saveProgress}
                disabled={savingProgress}
                className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800 disabled:opacity-50 shadow-md hover:shadow-lg transition-all"
              >
                {savingProgress ? 'Saving...' : 'Save Progress'}
              </button>
              
              {currentStep < 3 ? (
                <button
                  type="button"
                  onClick={() => handleStepChange('next')}
                  className="px-6 py-2 bg-custom-red text-white rounded-md hover:bg-red-600 shadow-md hover:shadow-lg transition-all"
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-6 py-2 bg-custom-red text-white rounded-md shadow-md hover:shadow-lg transition-all ${
                    isSubmitting ? 'opacity-75 cursor-not-allowed' : 'hover:bg-red-600'
                  }`}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit Application'}
                </button>
              )}
            </div>
          </div>
        </form>
      </BaseForm>
      
      {/* Render modals */}
      <ReferenceCodeModal />
      {renderLoadApplicationForm()}
    </>
  );
};

export default FinancierAccreditationForm;
