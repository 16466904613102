import React from "react";
import { Link } from 'react-router-dom';
import NavigationBar from "./NavigationBar";
import Heroimg from "../assets/herosection.png";
import Exhibitimg from "/exhibit.jpg";
import Aboutimg from "../assets/about.png";
import Masterclassimg from "/AFFF-connex-masterclass.jpg";
import Forumimg from "/forum-section.jpg";
import Footer from "./Footer";
import AnnouncementImage1 from "/pages-bg/8.png";
import AfffPatrons from "/afff-patrons.jpg";
import AnnouncementImage3 from "../assets/master-class-bg.jpg";
import BenBruce from "/Ben-Murray-Bruce.jpg";
import AlexKendrick from "../assets/alex-kendrick.webp";
import Partners from "./Partners";
import DealTable from "./DealTable";
import OtherSpeakers from "./OtherSpeakers";
import Speakers from "./Speakers";
import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";
import Accordion from "./Accordion";
import { FaAngleLeft, FaAngleRight, FaPlay, FaPause } from "react-icons/fa";
import Carousel from "./Carousel";
import Announcement from "./Announcement";
import RegistrationForm from './RegistrationForm';
import MasterClassEnrollmentForm from './MasterClassEnrollmentForm';
import axios from "axios";
import FinancierImage from "/call-for-sponsor-afff-connex.jpg"; // Adjust path as needed
import ProjectCallImage from "/call-for-projects-afff.jpg"; // Adjust path as needed
import ContactForm from "./ContactForm";

const DATA = [
  {
    id: 1,
    value: "3000",
    title: "Industry Stakeholders and Partners",
    color: "#00c0a3",
    bgColor: "bg-[#00c0a3]",
  },
  {
    id: 2,
    value: "100+",
    title: "Leading Brands",
    color: "#ff5c35",
    bgColor: "bg-[#ff5c35]",
  },
  {
    id: 3,
    value: "$10M",
    title: "Production deals",
    color: "#00a6bf",
    bgColor: "bg-[#00a6bf]",
  },
  {
    id: 4,
    value: "50+ ",
    title: "Curated Film ptches",
    color: "#f62d71",
    bgColor: "bg-[#f62d71]",
  },
];

const GRADIENTS = {
  1: "before:from-[#00c0a3] before:to-[#00a6bf] after:from-[#fcead7] after:to-[#00c0a3]",
  2: "before:from-[#ffbc4b] before:to-[#ff5c35] after:from-[#ff5c35] after:to-[#fcead7]",
  3: "before:from-[#00a6bf] before:to-[#00c0a3] after:from-[#fcead7] after:to-[#00a6bf]",
  4: "before:from-[#ff5c35] before:to-[#f62d71] after:from-[#f62d71] after:to-[#fcead7]",
};

const images = [
  "/home-carousel/cinotour.jpg",
  "/home-carousel/img2.png",
  "/home-carousel/img3.png",
  // Add all your carousel images here
];

function Home() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animation, setAnimation] = useState(""); // State to store the animation class
  const [displayForm, setDisplayForm] = useState(false);
  const intervalRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const [data, setData] = useState([
    {
      id: 1,
      value: "3000",
      title: "Industry Stakeholders and Partners",
      color: "#00c0a3",
      bgColor: "bg-[#00c0a3]",
    },
    {
      id: 2,
      value: "100+",
      title: "Leading Brands",
      color: "#ff5c35",
      bgColor: "bg-[#ff5c35]",
    },
    {
      id: 3,
      value: "$10M",
      title: "Production deals",
      color: "#00a6bf",
      bgColor: "bg-[#00a6bf]",
    },
    {
      id: 4,
      value: "50+ ",
      title: "Curated Film ptches",
      color: "#f62d71",
      bgColor: "bg-[#f62d71]",
    },
  ]);
  const [rotation, setRotation] = useState(135);
  // effect for the spinning
  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate next slide index here
      const nextSlideIndex = currentSlide % data.length;
      handleButtonClick(data[nextSlideIndex].id);
    }, 3000); // 10 seconds

    return () => clearInterval(interval);
  }, [currentSlide, data]);

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    resetInterval();
    intervalRef.current = setInterval(() => {
      nextHandler();
    }, 5000); // Change slide every 5 seconds

    return () => {
      resetInterval();
    };
  }, [currentIndex]);

  const prevHandler = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setAnimation('slide-right');
  };

  const nextHandler = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setAnimation('slide-left');
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
    setAnimation('fade');
  };

  const handleButtonClick = (slideID) => {
    setCurrentSlide(slideID);
    setRotation(rotation + 360); // Increase rotation by 360 degrees
  };

  const variants = {
    initial: { rotate: -45 },
    rotated: (custom) => ({ rotate: custom }), // what triggers the rotation
  };


 // Define announcements
 const announcements = [
  {
    title: "A Call for Financier Partnership",
    description: "Invest in Africa's Booming Film Industry at AFFF 2025. Unlock High-Return Investments in Africa's $20B Film Industry. The Africa Film Finance Forum (AFFF) 2025 is your gateway to exclusive, high-yield investment opportunities in Africa's rapidly growing film and entertainment sector.",
    image: FinancierImage,
    ctaText: "Partner With Us",
    link: "mailto:funding@africafilmfinanceforum.com",
    type: "financier",
    fullContent: `
      <p>The Africa Film Finance Forum (AFFF) 2025 is your gateway to exclusive, high-yield investment opportunities in Africa's rapidly growing film and entertainment sector. With over 200% ROI potential, this is the moment to position yourself at the forefront of a billion-dollar industry.</p>
      
      <h3 class="text-2xl font-bold my-4">Who We're Looking For:</h3>
      <ul class="list-disc pl-6 mb-4">
        <li>Private Equity Firms looking to invest in high-growth industries</li>
        <li>Venture Capitalists interested in innovative, scalable media ventures</li>
        <li>Banks and Financial Institutions seeking to expand into entertainment financing</li>
        <li>Angel Investors with an eye on emerging markets and untapped film opportunities</li>
        <li>Production Companies interested in co-financing and distribution partnerships</li>
      </ul>

      <h3 class="text-2xl font-bold my-4">As a Financier Partner, you gain:</h3>
      <ul class="list-check pl-6 mb-4">
        <li>✅ Exclusive access to premium film projects vetted by industry and financial experts</li>
        <li>✅ Private Investor Rooms for one-on-one meetings with top filmmakers, distributors, and industry executives</li>
        <li>✅ The AFFF Deal Table, where multi-million-dollar film investments are structured and signed</li>
        <li>✅ Data-driven investment insights to minimize risk and maximize profitability</li>
        <li>✅ Brand positioning as a key driver of Africa's creative economy</li>
      </ul>

      <p class="mt-4">Whether you're a bank, private equity firm, venture capitalist, or institutional investor, AFFF provides a secure, structured, and highly profitable platform for financing films, cinemas, and entertainment infrastructure across Africa.</p>
      
      <p class="font-bold mt-4">Contact us at funding@africafilmfinanceforum.com to discuss partnership opportunities.</p>
    `
  },
  {
    title: "Call for Projects: Secure Funding for Film Production, Infrastructure, and Distribution",
    description: "Are you a filmmaker, studio, or industry innovator looking for the right financing to bring your project to life? The Africa Film Finance Forum (AFFF) 2025 is now accepting applications for our exclusive Deal Table and Investor Pitch Sessions.",
    image: ProjectCallImage,
    ctaText: "Submit Your Project",
    link: "/exhibit/deal-table",
    type: "projects",
    fullContent: `
      <p>The Africa Film Finance Forum (AFFF) 2025 is now accepting applications for our exclusive Deal Table and Investor Pitch Sessions, where top financiers, investment firms, and industry leaders will be looking for the next big opportunity!</p>

      <h3 class="text-2xl font-bold my-4">🎬 What We're Looking For:</h3>
      <ul class="list-none pl-6 mb-4">
        <li>✔ Feature Films & Blockbusters – High-potential projects across multiple genres</li>
        <li>✔ Film Infrastructure & Exhibition Financing – Investment in studio development, cinemas, and production hubs</li>
        <li>✔ Distribution Platforms & Film Acquisitions – Funding for distribution channels, digital platforms, and content acquisition</li>
        <li>✔ Animation & Documentaries – Engaging projects with global market potential</li>
      </ul>

      <h3 class="text-2xl font-bold my-4">💰 Why Pitch at AFFF?</h3>
      <ul class="list-none pl-6 mb-4">
        <li>🔹 Direct access to financiers looking for high-ROI investment opportunities</li>
        <li>🔹 Strategic guidance from industry experts in both finance and film</li>
        <li>🔹 Exclusive data-backed verification to ensure your project attracts premium investors</li>
        <li>🔹 The chance to be part of Africa's booming creative economy with funding for production, infrastructure, and global distribution</li>
      </ul>

      <p class="mt-4">Don't miss this opportunity to connect with investors and turn your project into reality. Apply now and pitch your film, infrastructure, or distribution platform at AFFF 2025!</p>
    `
  },
  {
    title: "Registration Now Open for AFFF SUMMIT 2025!",
    description: "Join us September 16-18, 2025 for the continent's premier event connecting film, finance, and creative industries. Early Bird Registration available for just N75,000 (Nigeria) or $50 (International). Limited to 50 spots only! Don't miss this opportunity to be part of the future of African cinema!",
    image: AnnouncementImage1,
    ctaText: "Register Now",
    link: "/register#early-bird",
    type: "registration",
    fullContent: `
      <p>We are thrilled to announce that registration for the Africa Film Finance Forum (AFFF) 2024 is officially open! Be part of the continent's premier event connecting film, finance, and creative industries from November 15th – 18th.</p>
      <h3 class="text-2xl font-bold my-4">Early Bird Registration:</h3>
      <ul class="list-disc pl-6 mb-4">
        <li>Dates: September 16-18, 2025</li>
        <li>Price: N75,000 (Nigeria) or $50 (International)</li>
        <li>Limited to only 50 spots!</li>
        <li>Secure your spot at a discounted rate before prices go up!</li>
      </ul>
      <p>Don't miss this opportunity to join over 3,000 industry professionals, investors, and filmmakers for groundbreaking sessions, networking, and the chance to transform Africa's film landscape.</p>
      <p class="font-bold mt-4">Register now and be part of the future of African cinema!</p>
    `
  },
  {
    title: "Enroll Now in AFFF SUMMIT 2025 Masterclasses!",
    description: "Elevate your career with exclusive masterclasses from November 15th - 18th. Secure certifications, learn about film financing, maximize ROI, and gain insights from renowned experts. Limited slots available!",
    image: AnnouncementImage3,
    ctaText: "Enroll Now",
    link: "/programmes/master-class",
    type: "masterclass",
    fullContent: `
      <p>Elevate your career in film with our exclusive Masterclasses at the Africa Film Finance Forum (AFFF) 2024, running from November 15th – 18th.</p>
      <h3 class="text-2xl font-bold my-4">These masterclasses offer a unique opportunity to:</h3>
      <ul class="list-disc pl-6 mb-4">
        <li>Secure certifications from top global film schools.</li>
        <li>Get introduced to the business of film, understanding how film financing works and the full production cycle.</li>
        <li>Learn how to maximize ROI and make informed investments in the film industry.</li>
        <li>Gain insights from renowned African and global industry experts.</li>
      </ul>
      <p>This is your chance to dive deep into the business and art of film, positioning yourself for success in Africa's booming film industry.</p>
      <p class="font-bold mt-4">Limited slots available—register now and secure your place in shaping the future of African cinema!</p>
    `
  },
  {
    title: "Introducing the Ben Murray-Bruce Film Industry Patrons Awards",
    description: "Join us in celebrating the pioneers of African cinema at the inaugural Ben Murray-Bruce Film Industry Patrons Awards, November 26-28th at the Oriental Hotel, Lagos.",
    image: BenBruce,
    ctaText: "View Awards",
    link: "/awards",
    type: "awards",
    fullContent: `
      <p>We are thrilled to announce the inauguration of the Ben Murray-Bruce Film Industry Patrons Awards at the upcoming Africa Film Finance Forum (AFFF), taking place at the Oriental Hotel, Lagos, from November 26th to 28th. This prestigious awards ceremony is named in honor of Senator Ben Murray-Bruce, the visionary and trailblazer who pioneered the modern cinema industry in Nigeria.</p>
      <h3 class="text-2xl font-bold my-4">About the Awards:</h3>
      <ul class="list-disc pl-6 mb-4">
        <li>Recognize and celebrate individuals, organizations, and visionaries who have made significant contributions to the financing and development of African cinema.</li>
        <li>Honor those who have played a pivotal role in shaping the growth and evolution of the industry through financial support, fostering creative partnerships, and driving innovation.</li>
      </ul>
      <p>The awards will be presented during the AFFF, which serves as a unique platform bringing together filmmakers, investors, distributors, and key stakeholders in the African film industry. The forum will explore opportunities for collaboration, investment, and the future of African cinema.</p>
      <p class="font-bold mt-4">Join us in celebrating the pioneers who continue to push the boundaries of African storytelling, ensuring that our stories are told, funded, and shared with the world.</p>
      <p class="italic mt-2">Don't miss this remarkable event! November 26-28th at the Oriental Hotel, Lagos. Be part of Africa's film financing future.</p>
    `
  },
  {
    title: "Meet Our Distinguished AFFF Patrons - Ben Murray-Bruce and Farouk Khailann",
    description: "Discover the visionary leaders and financiers shaping the future of African cinema. Ben Murray-Bruce is a Nigerian businessman, media mogul, and former senator known for founding Silverbird Group and advocating for economic reform. Farouk Khailann, CEO - Premium Africa Holdings | Senior advisor - Royal Family Office, UA. Join us November 26-28th in Lagos, to celebrate their contributions.",
    image: AfffPatrons,
    ctaText: "View Patrons",
    link: "/patrons",
    type: "patrons",
    fullContent: `
      <p>We are proud to introduce the distinguished Patrons of the Africa Film Finance Forum (AFFF), an exclusive circle of visionary leaders, financiers, and pioneers who have played an instrumental role in advancing the growth of Africa's film industry.</p>
      <h3 class="text-2xl font-bold my-4">The Role of AFFF Patrons:</h3>
      <ul class="list-disc pl-6 mb-4">
        <li>Represent the backbone of African cinema, offering unwavering support and fostering critical partnerships.</li>
        <li>Enable exploration of new opportunities, unlock investments, and create lasting change in the African film landscape.</li>
        <li>Stand as champions of African stories, driving innovation, mentorship, and leadership.</li>
        <li>Recognize the transformative power of film and secure its future through sustainable financing models.</li>
      </ul>
      <p>We honor these exemplary figures who have not only invested in the film industry but have also committed to ensuring that African cinema thrives on a global scale.</p>
      <p class="font-bold mt-4">Join us in celebrating the Patrons of the AFFF as they lead the charge in making Africa's film industry a cornerstone of global cinema.</p>
      <p class="italic mt-2">November 26-28th, Oriental Hotel, Lagos—an event that will mark a new era in African film finance!</p>
    `
  },
  {
    title: "Special Guest: Alex Kendrick at AFFF SUMMIT 2025",
    description: "Don't miss acclaimed filmmaker Alex Kendrick as he shares insights on faith-based filmmaking, independent film financing, and cultural transformation through storytelling. November 26-28th at the Oriental Hotel, Lagos.",
    image: AlexKendrick,
    ctaText: "View Guest Profile",
    link: "/special-guests",
    type: "special-guest",
    fullContent: `
      <p>We are thrilled to announce Alex Kendrick, the acclaimed filmmaker, actor, and producer behind globally successful films like War Room, Courageous, and Overcomer, as a Special Guest at the Africa Film Finance Forum (AFFF)!</p>
      <h3 class="text-2xl font-bold my-4">Alex Kendrick's Contribution:</h3>
      <p>With his inspiring career in faith-based filmmaking, Alex Kendrick has made a significant impact by creating films that blend powerful storytelling with purpose, reaching millions around the world. His journey in independent filmmaking, with a focus on purpose-driven content, aligns perfectly with AFFF's mission to explore diverse opportunities in film finance and distribution.</p>
      <h3 class="text-2xl font-bold my-4">Join us as Alex Kendrick shares his incredible insights on:</h3>
      <ul class="list-disc pl-6 mb-4">
        <li>The intersection of faith, film, and finance</li>
        <li>Independent film financing strategies</li>
        <li>The role of storytelling in cultural transformation</li>
      </ul>
      <p class="font-bold mt-4">This is a rare opportunity to learn from one of the most impactful filmmakers of our time. Don't miss the chance to be part of this conversation and gain insights that can help shape your own journey in the film industry.</p>
      <p class="italic mt-2">Register now for AFFF SUMMIT 2025 and experience firsthand the wisdom and inspiration Alex Kendrick brings to the forum!</p>
    `
  },
];

const [showRegistrationModal, setShowRegistrationModal] = useState(false);
const [showMasterClassModal, setShowMasterClassModal] = useState(false);
const [showVideoModal, setShowVideoModal] = useState(false);


  const handleRegisterClick = () => {
    setShowRegistrationModal(true);
  };

  const handleMasterClassClick = () => {
    setShowMasterClassModal(true);
  };

  const handleCloseModal = () => {
    setShowRegistrationModal(false);
    setShowMasterClassModal(false);
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    // Implement actual video play/pause functionality here
    const video = document.getElementById('hero-video');
    if (video) {
      isPlaying ? video.pause() : video.play();
    }
  };

  return (
    <>
      <NavigationBar />

      {/* Updated Hero Section */}
      <div className="relative bg-[#002630] text-white overflow-hidden mt-[-138px]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-[160px] sm:pt-[180px] md:pt-[200px] lg:pt-[220px] pb-20 md:pb-32 lg:pb-40 flex flex-col items-start">
          {/* Date and venue - shows at top on mobile, above video on desktop */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="mb-3 font-lexend text-xs sm:text-sm md:text-base font-medium text-[#f8f5ee] tracking-wider w-full lg:hidden"
          >
            September 16 - 18, 2025<br />
            Lagos Oriental Hotel, Nigeria
          </motion.p>
          
          {/* Content container for desktop/mobile layout */}
          <div className="flex flex-col lg:flex-row w-full">
            {/* Left content */}
            <div className="w-full lg:w-1/2 z-10 lg:pr-8 order-1 lg:order-1">
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="font-lexend text-2xl md:text-2xl lg:text-2xl font-bold mb-6 leading-tight tracking-wider text-[#f8f5ee]"
              >
                Finance Meets Africa's Best at <span className="block text-custom-brown text-5xl md:text-6xl lg:text-7xl font-header">Africa Film Finance Forum</span>
              </motion.h1>
              
              {/* Description text - below heading on desktop, below video on mobile */}
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
                className="font-lexend text-lg mb-8 max-w-xl !text-[#b9cdbe] font-light lg:block hidden"
              >
                Leveraging insights from AFFF Connex 2024, The AFFF 2025 Summit brings together Africa's creative leaders, global financiers, and industry innovators to shape the future of African film, unlock new possibilities.
              </motion.p>
              
              {/* Simple left-aligned register button */}
              <div className="text-left w-full lg:block hidden">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.6 }}
                >
                  <Link to="/register#early-bird" className="inline-block">
                    <button className="bg-custom-red hover:bg-red-700 transition-colors duration-300 text-white font-bold py-4 px-8 rounded-tl-lg rounded-br-lg text-lg uppercase tracking-wider font-lexend">
                      Register Now
                    </button>
                  </Link>
                </motion.div>
              </div>
            </div>
            
            {/* Right content - Video/Image */}
            <motion.div 
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.5, type: "spring", damping: 20 }}
              className="w-full lg:w-1/2 mt-6 lg:mt-0 relative rounded-xl overflow-hidden shadow-2xl order-2 lg:order-2"
            >
              {/* Date and venue above video - desktop only */}
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="mb-3 font-lexend text-base font-medium text-[#f8f5ee] tracking-wider hidden lg:block"
              >
                September 16 - 18, 2025<br />
                Lagos Oriental Hotel, Nigeria
              </motion.p>
              
              <div className="relative rounded-xl overflow-hidden" style={{ paddingTop: '56.25%' }}> {/* Maintains 16:9 aspect ratio */}
                {/* Poster image background */}
                <img 
                  src="/connex-2025-redcarpet.jpg"
                  alt="AFFF 2025 Promo"
                  className="absolute top-0 left-0 w-full h-full object-cover"
                />
              
                {/* Replace with your video or main promotional image */}
                <div className="absolute inset-0 bg-black bg-opacity-30">
                  {/* Centered Play Button */}
                  <button
                    onClick={() => setShowVideoModal(true)}
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 h-16 bg-black bg-opacity-50 hover:bg-opacity-70 rounded-full flex items-center justify-center transition-all duration-300"
                  >
                    <FaPlay className="text-white text-xl ml-1" />
                  </button>
                </div>
                
                {/* Video controls */}
                <div className="absolute bottom-5 right-5 flex space-x-4">
                  <button 
                    onClick={() => setShowVideoModal(true)}
                    className="bg-black bg-opacity-50 hover:bg-opacity-70 px-4 py-2 rounded-lg flex items-center justify-center transition-all duration-300"
                  >
                    <span className="text-white text-sm font-medium mr-2">FULL VIDEO</span>
                    <FaPlay className="text-white text-xs" />
                  </button>
                </div>

                {/* Optional: Pause Button (when video is playing) */}
                <div className="absolute bottom-5 left-5">
                  <button
                    className="bg-black bg-opacity-50 hover:bg-opacity-70 w-12 h-12 rounded-full flex items-center justify-center transition-all duration-300"
                    aria-label="Pause video"
                  >
                    <span className="text-white">II</span>
                  </button>
                </div>
              </div>
            </motion.div>

            {/* Mobile description - below video */}
            <div className="w-full order-3 mt-8 lg:hidden">
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
                className="font-lexend text-lg mb-8 !text-[#b9cdbe] font-light"
              >
                Leveraging insights from AFFF Connex 2024, The AFFF 2025 Summit brings together Africa's creative leaders, global financiers, and industry innovators to shape the future of African film, unlock new possibilities.
              </motion.p>
              
              {/* Mobile CTA button */}
              <div className="text-left w-full">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.6 }}
                >
                  <Link to="/register#early-bird" className="inline-block">
                    <button className="bg-custom-red hover:bg-red-700 transition-colors duration-300 text-white font-bold py-4 px-8 rounded-lg text-lg uppercase tracking-wider font-lexend w-full md:w-auto">
                      Register Now
                    </button>
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Background decoration - similar to the orange shape in the reference */}
        <div className="absolute -bottom-24 -left-24 w-64 h-64 rounded-full bg-custom-brown opacity-30 blur-3xl"></div>
        <div className="absolute -top-24 -right-24 w-96 h-96 rounded-full bg-custom-brown opacity-20 blur-3xl"></div>
      </div>

      {/*Annoucements */}
      <Announcement announcements={announcements} />
      
      {/* why attend the forum section*/}
      <section className="relative h-full px-[1rem] py-[4rem] sm:px-[2rem] lg:px-[5rem]">
        <div className="w-full h-full flex flex-col lg:flex-row lg:gap-8 gap-24 bg-white transition ease-in-out duration-300">
          {/* First div - Content */}
          <div className="w-full lg:w-[60%]">
            <h1 className="font-['Agharti_Condensed'] text-[5rem] text-center text-custom-base font-bold word-spacing-[10rem] custom-large:text-left tracking-wider leading-tight sm:leading-none">
              WHY ATTEND THE FORUM?
            </h1>
            <p className="text-center font-semi-bold font-exo mb-4 mt-4 text-[14px] custom-range:text-[18px] custom-large:text-left custom-large:text-[20px]">
              Africa Film Finance Forum (AFFF) provides a curated platform where
              financiers can gain insights into the industry while film
              professionals learn how to secure financing, sign lucrative deals,
              connect with reputable producers and production companies, and
              expand their portfolios. Join us for an enriching experience in a
              safe and conducive environment for collaboration and growth.
            </p>

            {/* Accordion component to display informations */}
            <Accordion
              title="Investors  "
              topic="FINANCIERS"
              content={[
                "Investment Opportunities: Discover lucrative investment opportunities in Africa's burgeoning film industry. Learn about high-potential projects and how to capitalize on them. Attend pitch sessions",
                "Market Insights: Gain deep insights into the African film market, including trends, audience preferences, and box office performance, to make informed investment decisions.",
                "Networking: Connect with industry leaders, filmmakers, and other financiers. Build valuable relationships and explore potential collaborations and partnerships",
                "Risk Mitigation: Understand the unique challenges and risks of investing in the film industry and learn strategies to mitigate these risks for a safer investment journey.",
                "Innovative Financing Models: Explore new and innovative financing models tailored to the African context, including crowdfunding, co-productions, and public-private partnerships.",
                "Technological Advancements: Learn how cutting-edge technology is transforming film production, distribution, and audience engagement, enhancing ROI.",
                "Government Support: Hear from policymakers about government incentives, subsidies, and policies designed to support and encourage investment in the film sector.",
                "Brand Integration: Discover how brands are leveraging film productions for marketing and branding, creating additional revenue streams and enhancing production value.",
                "Distribution Channels: Understand the importance of diverse distribution channels in ensuring a film's economic success and how they can maximize your returns.",
                "Expert Panels and Workshops: Participate in panels and workshops led by industry experts, offering practical advice and actionable insights on film financing and investment.",
                "Economic Impact: Learn about the broader economic impact of investing in the creative industry, contributing to job creation, cultural preservation, and economic growth.",
                "Success Stories: Hear success stories from other investors and financiers who have profited from the African film industry, providing inspiration and proven strategies.",
                "Foster International Collaboration: Forge partnerships and collaborations on a global scale, opening doors to international markets and audiences.",
                "",
                "By attending the Africa Film Finance Forum, financiers will not only gain a comprehensive understanding of the African film landscape but also discover how to effectively navigate and profit from this dynamic and rapidly growing industry.",
              ]}
            />
            <Accordion
              title="Investables"
              topic="PRODUCTION & SALES COMPANIES"
              content={[
                "Engage with Accredited Investors/Financiers: Meet with investors and financiers who are actively seeking opportunities to support innovative film projects, providing a direct avenue to secure essential funding.",
                "Network with Top Brands: Connect with over 100 leading brands for potential partnerships and collaborations that can elevate your projects, enhance marketing efforts, and increase visibility.",
                "Foster International Collaboration: Forge partnerships and collaborations on a global scale, opening doors to international markets and audiences, thereby expanding your project's reach and success potential.",
                "Seize Pre-Sales Opportunities: Capitalize on pre-sales opportunities to secure funding and distribution for your projects before they even hit the market, ensuring financial stability and reduced risk.",
                "Showcase Your Work: Present your projects to accredited financiers, offering them a first look at your innovative ideas and gaining their interest and support.",
                "Meet Industry Leaders: Network with top investors and industry leaders who can provide valuable insights and opportunities for growth, helping you stay ahead of industry trends and best practices.",
                "Secure Financing for Your Projects: Learn about diverse financing options and sign profitable deals to finance your current and future film projects, ensuring a steady pipeline of productions.",
                "Deal-Making Opportunities: Take advantage of millions of dollars/niara in deal-making opportunities, offering a platform for pitching projects, negotiating deals, and securing partnerships, distribution agreements, or co-production opportunities.",
                "By attending the AFFF, production and sales companies can significantly boost their chances of securing funding, forming strategic partnerships, and expanding their market reach, all while gaining invaluable industry insights and connections.",
              ]}
            />

            <Accordion
              title="Invested"
              topic="BRANDS THAT SUPPORT MOVIES"
              content={[
                "Strategic Partnerships: Connect with filmmakers, producers, and distributors to explore opportunities for strategic partnerships and co-branding initiatives that can enhance your brand's visibility and reach.",
                "Innovative Marketing: Discover innovative ways to integrate your brand into film projects through product placements, sponsorships, and branded content, effectively reaching a wider and more engaged audience.",
                "Targeted Audience Engagement: Engage with a diverse and captive audience through film, aligning your brand with content that resonates with target demographics and cultural narratives.",
                "Content Creation: Collaborate with creative professionals to develop original content that can be used in your marketing campaigns, enhancing your brand storytelling and authenticity.",
                "Boost Brand Image: Enhance your brand image by associating with high-quality, impactful films and supporting the growth of the African film industry, which is rich in cultural narratives and stories.",
                "Networking Opportunities: Network with industry leaders, influencers, and decision-makers in the film and creative sectors, opening doors to new business opportunities and collaborations.",
                "Brand Visibility: Gain significant brand exposure by participating in a high-profile event that attracts key stakeholders from across the film and entertainment industries.",
                "Market Insights: Gain valuable insights into the African film market, understanding consumer trends, audience preferences, and market dynamics to better tailor your marketing strategies.",
                "Corporate Social Responsibility (CSR): Enhance your CSR initiatives by supporting the creative economy, contributing to the growth and development of local talent and the broader cultural landscape.",
                "Exclusive Sponsorship Opportunities: Take advantage of exclusive sponsorship opportunities to position your brand prominently throughout the event, increasing brand recall and affinity among attendees.",
                "Leveraging Technology: Learn about the latest technological advancements in film production and distribution, and how these can be leveraged for innovative marketing and brand integration strategies.",
                "ROI on Brand Investments: Understand the return on investment for brand placements and sponsorships in films, backed by success stories and data-driven insights from previous collaborations.",
                "By attending the AFFF, brands can effectively enhance their marketing efforts, build strong industry relationships, and significantly boost their presence and impact within the dynamic and rapidly growing African film industry.",
              ]}
            />
            <Accordion
              title="Interested"
              topic="INDUSTRY PROFESSIONALS"
              content={[
                "Career Advancement: Gain access to new career opportunities and advancements by connecting with influential leaders, decision-makers, and key players in the film and creative industry.",
                "Networking: Build and expand your professional network by meeting other industry professionals, including producers, directors, financiers, distributors, and marketers.",
                "Knowledge Sharing: Engage in meaningful discussions and knowledge sharing with peers and experts about the latest trends, technologies, and best practices in the film industry.",
                "Professional Development: Attend workshops, panel discussions, and keynote speeches that provide insights into current and future industry trends, equipping you with valuable skills and knowledge.",
                "Market Trends: Stay informed about market trends and audience preferences in the African film industry, helping you to make more informed decisions in your professional role.",
                "Financing Insights: Learn about various financing options, investment opportunities, and strategies for securing funding for film projects, from traditional methods to innovative solutions like crowdfunding.",
                "Creative Inspiration: Draw inspiration from successful case studies and the creative projects showcased at the forum, fueling your own creative endeavors and projects.",
                "Technological Innovations: Discover how new technologies are transforming film production, distribution, and audience engagement, and learn how to incorporate these advancements into your work.",
                "Collaboration Opportunities: Identify and engage in potential collaborations and partnerships with other professionals, companies, and brands that can enhance your projects and career.",
                "Regulatory Updates: Gain insights into the latest government policies, regulations, and incentives affecting the film industry, ensuring you stay compliant and take advantage of available opportunities.",
                "Global Perspective: Understand the global landscape of the film industry and how African films can compete and collaborate internationally, broadening your professional outlook and opportunities.",
                "Cultural Exchange: Participate in cultural exchanges that celebrate the diversity and richness of African cinema, enhancing your appreciation and understanding of different storytelling traditions and practices.",
                "Recognition and Prestige: Enhance your professional profile by being part of a prestigious event, contributing to discussions, and potentially being recognized for your contributions to the industry.",
                "Problem-Solving: Address and find solutions to common challenges in the film industry, from funding and distribution to audience engagement and technological integration.",
                "By attending the AFFF, industry professionals significantly enhance their knowledge, skills, and networks, positioning themselves for greater success and influence within the dynamic and growing African film industry.",
              ]}
            />

            {/* call to action button CTA */}
            <Link 
                to="/about" 
                className="px-4 py-2 mt-6 uppercase font-bold cursor-pointer bg-custom-red rounded-tl-lg rounded-br-lg text-white font-roboto text-[18px] inline-block"
              >
                LEARN MORE
              </Link>
          </div>

          {/* Second div - Stats Circle */}
          <div className="w-full lg:w-[40%] flex justify-center lg:justify-start lg:items-center">
            <div className="relative grid size-[340px] place-content-center overflow-hidden rounded-full bg-transparent sm:size-[360px] md:size-[389px] custom-range:size-[600px]">
              <motion.div
                initial="initial"
                variants={variants}
                custom={rotation}
                animate="rotated"
                transition={{ duration: 0.5, ease: "circOut" }}
                className={clsx(
                  "absolute left-0 top-0 z-10 size-full before:absolute before:-top-1/2 before:right-0 before:block before:size-full before:bg-gradient-to-tl after:absolute after:right-0 after:top-1/2 after:block after:size-full after:bg-gradient-to-tl",
                  GRADIENTS[currentSlide]
                )}
              ></motion.div>
              {/* Substracting 1 from the currentslide because the data an array an index starts from 0 */}
              <div
                className={clsx(
                  "z-20 grid size-[227px] place-content-center rounded-full bg-white  p-5 text-center font-black text-teal-700 transition-all sm:size-[250px] md:size-[277px] custom-range:size-[410px]",
                  `text-${DATA[currentSlide - 1].color}`
                )}
              >
                <div>
                  <h1
                    style={{ color: `${DATA[currentSlide - 1].color}` }}
                    className={clsx("text-6xl tracking-tight")}
                  >
                    {DATA[currentSlide - 1].value}
                  </h1>
                  <p
                    style={{ color: `${DATA[currentSlide - 1].color}` }}
                    className="mt-3 text-sm uppercase tracking-widest"
                  >
                    {DATA[currentSlide - 1].title}
                  </p>
                  {/* Nav buttons */}
                  <div className="flex translate-y-8 justify-center space-x-1.5">
                    {DATA.map((d) => (
                      <button
                        key={d.id}
                        className={clsx(
                          {
                            [`${d.bgColor} border-none`]: d.id === currentSlide,
                          },
                          "size-3 rounded-full border-2 border-teal-900 transition-all duration-300"
                        )}
                        onClick={() => handleButtonClick(d.id)}
                      ></button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* forum section */}
      <section className=" relative h-[1200px] md:h-[500px] lg:h-full overflow-hidden ">
       
          {/* slanted div section */}
          <div className="w-full h-[80%] clip-custom-slash custom-mobile:h-[72%] sm:h-[67%] sm:clip-custom-sm md:clip-custom-md md:h-full absolute inset-0 z-[-10] bg-custom-forum lg:clip-path-reverse transition ease-[.35s]">
            <svg
              className="absolute right-[-3.5rem] animate-bounceUnique"
              width="152"
              height="151"
              viewBox="0 0 481 481"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse
                cx="240.5"
                cy="240.46"
                rx="239.578"
                ry="239.777"
                transform="rotate(53.2113 240.5 240.46)"
                fill="url(#paint0_linear_1436_136)"
              />
            </svg>
          </div>

          {/* image container */}
          <div className="absolute bottom-0 lg:top-0 lg:left-0 lg:w-[60%] lg:h-full z-[-15] overflow-hidden">
            <div className="md:hidden lg:block w-full h-full relative">
              <img
                className="w-full h-full object-cover object-center absolute inset-0 filter brightness-50"
                src={Forumimg}
                alt="exhibit image"
              />
              <div className="absolute inset-0 bg-custom-forum clip-custom-slash custom-mobile:clip-custom-sm sm:clip-custom-sm md:clip-custom-md lg:clip-path-reverse"></div>
            </div>
          </div>
        {/* content container */}
        <div className=" px-[1rem] pt-[3rem] pb-[5rem]  lg:pt-[3rem] lg:w-[40%] lg:ml-[50%] bg-custom-forum">
          <h1 className="font-['Agharti_Condensed'] text-[5rem] text-center mb-3 font-bold text-custom-master tracking-wider lg:text-left">
            ABOUT AFFF
          </h1>
          <p className=" text-center font-exo text-white text-[16px] mb-4 lg:text-[18px] lg:text-left">
            The Africa Film Finance Forum (AFFF) will serve as a gathering
            ground for speakers and participants hailing from diverse industries
            and countries. Expect to engage with representatives from leading
            nations across Africa with burgeoning film sectors, such as Nigeria,
            Ghana, South Africa, Egypt, Kenya, Cameroon, Mali, Gabon, and
            several others. Additionally, the forum will draw in prominent
            figures from Hollywood, as well as experts and delegates from India,
            Europe, China, Brazil, and the UK.
          </p>
          <p className=" text-center font-exo text-white text-[16px] mb-4 lg:text-[18px] lg:text-left">
            Our primary goal is to capture the attention of investors, not only
            by showcasing Africa's potential in the film industry but also by
            offering a pragmatic platform that guides them toward tailored
            investment opportunities with promising returns. Attendees will have
            the opportunity to explore a curated selection of viable projects
            that align with their investment objectives.
          </p>
          <p className="text-center font-exo text-white text-[16px] mb-4 lg:text-[18px] lg:text-left">
            At AFFF, we are driven by a mission to foster connections and
            knowledge-sharing within the film industry. Through strategic
            networking, access to pertinent information, and guidance on
            effective strategies, we aim to empower industry professionals to
            navigate the global landscape and thrive in their respective
            endeavors. Join us as we pave the way for a dynamic and prosperous
            future in the world of filmmaking..
          </p>
        </div>
      </section>

      {/* $20billion potential */}
      <section className="bg-custom-light">
        <div className="mx-auto max-w-screen-xl px-4 py-8 font-normal sm:px-6 sm:py-12 lg:px-12 lg:py-16">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            {/* image container  */}
            <div className="relative  order-last h-64 overflow-hidden rounded-lg sm:h-80 lg:h-full">
              <img
                alt=""
                src={Aboutimg}
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
            {/* content container  */}
            <div>
              <h1 className="font-header text-[3rem] text-custom-base text-center leading-none font-bold mb-6 lg:w-[70%] lg:text-left tracking-wider">
                OVER 20 MILLION JOBS CONTRIBUTING $20 BILLION TO AFRICA'S
                ECONOMY - <span className="font-normal">UNESCO</span>
              </h1>

              <p className="font-exo text-center text-[18px] mb-4 lg:w-[90%] lg:text-left">
                "Africa's film and audiovisual industries could create over 20
                million jobs and contribute $20 billion to the continent's
                combined Gross Domestic Product (GDP)", - UN cultural agency,
                UNESCO
              </p>
              <p className="font-exo text-center text-[16px] mb-4 lg:w-[90%] lg:text-left">
                According to the report Africa currently employs some five
                million people and accounts for $5 billion in GDP across Africa.
                Africa's potential as a film powerhouse remains largely
                untapped, despite significant growth in production across the
                continent, the report argues. Nigeria alone produces around
                2,500 films a year.{" "}
              </p>
              <p className="font-exo text-center text-[16px] mb-4 lg:w-[90%] lg:text-left">
                Africa is the world's second-largest and second-most-populous
                continent with 1.4 billion people as of 2021, it accounts for
                about 18% of the world's human population. This population is
                grossly unexploited, which means that there is a lot of
                opportunity for the growth of the industry, the Film industry is
                an industry that capitalizes on population and numbers to
                attract its mega gross incomes, so the focus should be REACH.
              </p>

              {/* call to action button CTA */}
              <div className="flex justify-center lg:justify-start">
            <Link 
                to="/programmes" 
                className="px-4 py-2 mt-6 uppercase font-bold cursor-pointer bg-custom-red rounded-tl-lg rounded-br-lg text-white font-roboto text-[18px] inline-block"
              >
                LEARN MORE
              </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* exhibition section  */}
      <section className="relative h-[950px] md:h-[400px] custom-mobile:h-[1050px] sm:h-[1200px] lg:h-full overflow-hidden">
        {/* slanted div section */}
        <div className="w-full h-[75%] clip-custom-slash custom-mobile:h-[69%] sm:h-[67%] sm:clip-custom-sm md:clip-custom-md md:h-full absolute inset-0 z-[-10] bg-[#F1676480] lg:bg-custom-exhibit lg:h-full lg:clip-path-custom2 transition ease-[.35s]">
          <svg
            className="absolute left-[-5rem] animate-bounceUnique"
            width="152"
            height="151"
            viewBox="0 0 242 240"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3166 64.6077C43.3149 6.28218 115.707 -16.2744 175.01 14.2263C234.312 44.7269 258.068 116.735 228.069 175.06C198.071 233.386 125.679 255.942 66.3764 225.442C7.07405 194.941 -16.6816 122.933 13.3166 64.6077Z"
              fill="url(#paint0_linear_2288_98)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2288_98"
                x1="86.4457"
                y1="779.502"
                x2="195.079"
                y2="568.287"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#A18749" />
                <stop offset="1" stopColor="#594A28" stopOpacity="0.2" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        {/* image container */}
        <div className="absolute bottom-0 lg:top-0 lg:right-0 lg:w-[60%] lg:h-full z-[-15] ">
          <div className="w-full h-full">
            <img
              className="md:hidden lg:block w-full h-full object-cover"
              src={Exhibitimg}
              alt="exhibit image"
            />
          </div>
        </div>
        {/* content container */}
        <div className="w-full px-[1rem] lg:ml-[5rem] pt-[3rem] lg:px-0 lg:w-[40%]">
          <h1 className="font-['Agharti_Condensed'] text-[5rem] text-center lg:text-left tracking-wider">
            EXHIBIT/SHOWCASE
          </h1>
          <p className=" font-exo text-center mb-2 text-[18px] lg:text-left">
            Our exhibition space offers an unparalleled opportunity for key
            players in the film and finance industries to showcase their
            products and services to an audience of over 3000 industry experts
            who perfectly align with their customer and vendor profiles.
          </p>
          <p className=" font-exo text-center text-[18px] lg:text-left">
            This event caters to both business-to-business (B2B) and
            business-to-consumer (B2C) interactions, providing a tailored
            experience for all participants. Exhibitors will be provided with
            dedicated spaces for the duration of the three-day event.
          </p>
          {/* call to action button CTA */}
          <div className="flex flex-wrap justify-center gap-4 mt-16">
            <a 
              href="/exhibit/how-to-exhibit" 
              className="px-8 py-3 bg-white hover:bg-gray-100 text-[#1E2323] font-bold text-lg tracking-wider rounded-tl-lg rounded-br-lg shadow-[0_8px_16px_rgba(255,255,255,0.15)] transition-all duration-300 hover:shadow-[0_12px_20px_rgba(255,255,255,0.25)] transform hover:scale-105"
            >
              HOW TO EXHIBIT
            </a>
            <button 
              onClick={() => setShowContactModal(true)}
              className="px-8 py-3 bg-[#F16764] hover:bg-[#e64e4b] text-white font-bold text-lg tracking-wider rounded-tl-lg rounded-br-lg shadow-[0_8px_16px_rgba(255,255,255,0.15)] transition-all duration-300 hover:shadow-[0_12px_20px_rgba(255,255,255,0.25)] transform hover:scale-105"
            >
              BOOK A STAND
            </button>
          </div>
        </div>
      </section>

      {/* masterclass section */}
      <section className="relative mt-[-0.1rem] h-[1100px] sm:h-[1200px] md:h-[500px] lg:h-full overflow-hidden">
        {/* slanted div section */}
        <div className="w-full h-[79%] clip-custom-slash custom-mobile:h-[75%] sm:h-[75%] sm:clip-custom-sm md:clip-custom-md md:h-full absolute inset-0 z-[-10] bg-[#F1676480] lg:bg-custom-exhibit lg:h-full lg:clip-path-slant transition ease-[.35s]">
          <svg
            className="absolute right-[-4.5rem] animate-bounceUnique"
            width="152"
            height="151"
            viewBox="0 0 242 240"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3166 64.6077C43.3149 6.28218 115.707 -16.2744 175.01 14.2263C234.312 44.7269 258.068 116.735 228.069 175.06C198.071 233.386 125.679 255.942 66.3764 225.442C7.07405 194.941 -16.6816 122.933 13.3166 64.6077Z"
              fill="url(#paint0_linear_2288_98)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2288_98"
                x1="86.4457"
                y1="779.502"
                x2="195.079"
                y2="568.287"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#A18749" />
                <stop offset="1" stopColor="#594A28" stopOpacity="0.2" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        {/* image container */}
        <div className="absolute bottom-0 lg:top-0 lg:left-0 lg:w-[60%] lg:h-full z-[-15] ">
          <div className="w-full h-full">
            <img
              className="md:hidden lg:block w-full h-full object-cover"
              src={Masterclassimg}
              alt="exhibit image"
            />
          </div>
        </div>
        {/* content container */}
        <div className="pt-[3rem] px-[1rem] w-full lg:w-[40%] lg:ml-[50%] ">
          <h1 className="font-['Agharti_Condensed'] text-[5rem] text-center mb-2 text-custom-master tracking-wider lg:text-left">
            MASTERCLASS
          </h1>
          <p className=" font-exo  text-center mb-2 text-[18px] lg:text-left">
            Join us for an exclusive master class, a unique workshop where
            industry titans from both the movie and finance sectors come
            together to impart their wisdom. Whether you're a seasoned
            professional or an aspiring filmmaker or investor, this is your
            chance to glean insights from the best in the business.
          </p>
          <p className=" font-exo text-center text-[18px] lg:text-left">
            Our master class sessions delve deep into various facets of
            filmmaking and financing, covering everything from directing and
            acting to cinematography. Participants will gain valuable knowledge,
            uncover financing secrets, and draw inspiration from the experts.
            It's an unparalleled opportunity to access insider tips and tricks
            directly from the masters themselves.
          </p>
          {/* call to action button CTA */}
          <div className="flex justify-center lg:justify-start mb-5">
          <Link 
                to="/register" 
                className="px-10 py-2 mt-4 mb-6 uppercase font-bold cursor-pointer bg-custom-red rounded-tl-lg rounded-br-lg text-white font-roboto text-[18px] inline-block"
              >
              Register for Masterclass
          </Link>
          </div>
        </div>
      </section>

      {/* Deal table Ad */}
      <DealTable />

      {/* Keynote Speakers */}
      {/* <Speakers /> */}

      {/* Other Speakers */}
      <OtherSpeakers />

    {/* Cinetour */}
    <section className="w-full px-4 sm:px-6 md:px-8 lg:px-12 py-16 md:py-20 group overflow-hidden relative">
      {/* Background image and overlay */}
      <div 
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{ backgroundImage: "url('/pages-bg/16.png')" }}
      >
        <div className="absolute inset-0 bg-black opacity-85"></div>
      </div>
      
      {/* Content */}
      <div className="max-w-[1920px] mx-auto relative z-10">
        <div className="w-full md:w-[85%] lg:w-[90%] mx-auto relative">
          <div className="w-full pt-[45%] relative">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`carousel ${index + 1}`}
                className={clsx(
                  "absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000",
                  {
                    "opacity-100": currentIndex === index,
                    "opacity-0": currentIndex !== index,
                    [animation]: currentIndex === index
                  }
                )}
                style={{ 
                  transition: "opacity 1s ease-in-out",
                  boxShadow: "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px",
                  border: "2px solid #FFF8DC"
                }}
              />
            ))}
          </div>

          {/* Navigation buttons - Made larger and more visible */}
          <button
            onClick={prevHandler}
            className="absolute top-1/2 -translate-y-1/2 left-4 sm:left-6 text-3xl rounded-full p-3 bg-black/30 text-white cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity hover:bg-black/50"
            aria-label="Previous slide"
          >
            <FaAngleLeft size={32} />
          </button>
          <button
            onClick={nextHandler}
            className="absolute top-1/2 -translate-y-1/2 right-4 sm:right-6 text-3xl rounded-full p-3 bg-black/30 text-white cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity hover:bg-black/50"
            aria-label="Next slide"
          >
            <FaAngleRight size={32} />
          </button>

          {/* Indicators - Made larger */}
          <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 flex gap-3">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={clsx(
                  "w-3 h-3 rounded-full transition-all duration-300",
                  currentIndex === index ? "bg-white w-6" : "bg-white/50"
                )}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
          
          {/* Call-to-action buttons - Adding both buttons side by side */}
          <div className="flex flex-wrap justify-center gap-4 mt-16">
            <a 
              href="/exhibit/how-to-exhibit" 
              className="px-8 py-3 bg-white hover:bg-gray-100 text-[#1E2323] font-bold text-lg tracking-wider rounded-tl-lg rounded-br-lg shadow-[0_8px_16px_rgba(255,255,255,0.15)] transition-all duration-300 hover:shadow-[0_12px_20px_rgba(255,255,255,0.25)] transform hover:scale-105"
            >
              HOW TO EXHIBIT
            </a>
            <button 
              onClick={() => setShowContactModal(true)}
              className="px-8 py-3 bg-[#F16764] hover:bg-[#e64e4b] text-white font-bold text-lg tracking-wider rounded-tl-lg rounded-br-lg shadow-[0_8px_16px_rgba(255,255,255,0.15)] transition-all duration-300 hover:shadow-[0_12px_20px_rgba(255,255,255,0.25)] transform hover:scale-105"
            >
              BOOK A STAND
            </button>
          </div>
        </div>
      </div>
    </section>
    
    {/* Footer */}
    <section>
      <div className="w-full h-full">
      <Footer />
     </div>
    </section>

    {/* Video Modal */}
    <AnimatePresence>
      {showVideoModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
        >
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-lg shadow-xl w-full max-w-5xl overflow-hidden"
          >
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-xl font-bold">AFFF 2025 Promo Video</h3>
              <button 
                onClick={() => setShowVideoModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="relative" style={{ paddingTop: '56.25%' }}>
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/wBDOj3DKPRg?autoplay=1&rel=0"
                title="AFFF Promotional Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>

    {/* Contact Form Modal */}
    <AnimatePresence>
      {showContactModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
        >
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-lg shadow-xl w-full max-w-2xl overflow-hidden"
          >
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-2xl font-bold text-[#1E2323]">Book an Exhibition Stand</h3>
              <button 
                onClick={() => setShowContactModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="p-6">
              <ContactForm onClose={() => setShowContactModal(false)} />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
    </>
  );
}

export default Home;