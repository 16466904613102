import React, { useState, createContext, useContext } from 'react';
import Dropup from "../assets/dropup.png";
import Dropdown from "../assets/dropdown.png";

// Create context for accordion state
const AccordionContext = createContext();

const Accordion = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  // Add index to child items if not already provided
  const childrenWithIndex = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child) && child.type === AccordionItem) {
      return React.cloneElement(child, { index: child.props.index ?? index });
    }
    return child;
  });
  
  return (
    <AccordionContext.Provider value={{ activeIndex, setActiveIndex }}>
      <div className="accordion">{childrenWithIndex}</div>
    </AccordionContext.Provider>
  );
};

const AccordionItem = ({ children, index }) => {
  const { activeIndex } = useContext(AccordionContext);
  const isActive = index === activeIndex;
  
  return (
    <div className="accordion-item">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { isActive, index });
        }
        return child;
      })}
    </div>
  );
};

const AccordionHeader = ({ children, isActive, index, className }) => {
  const { activeIndex, setActiveIndex } = useContext(AccordionContext);
  
  const handleClick = () => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  
  return (
    <div 
      className={`accordion-header flex justify-between items-center cursor-pointer ${className || ''}`} 
      onClick={handleClick}
    >
      <div>{children}</div>
      <div className="ml-auto">
        {isActive ? (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
          </svg>
        ) : (
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        )}
      </div>
    </div>
  );
};

const AccordionBody = ({ children, isActive, className }) => {
  if (!isActive) return null;
  
  return (
    <div className={`accordion-body ${className || ''}`}>
      {children}
    </div>
  );
};

// Add components as properties of the main Accordion component
Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;

function AccordionComponent({ title, content, topic }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      onClick={toggleAccordion}
      className=" w-[100%] border mb-4 rounded-lg overflow-hidden cursor-pointer custom-large:w-[90%]"
    >
      <div className="pl-5 flex items-center justify-between bg-gray-100">
        <div className="flex items-center gap-6">
          <h6 className="text-[14px] font-body text-gray-500 sm:text-[16px]">
            {title}
          </h6>
          <h2 className="text-[12px] font-body border-l-2 border-gray-300 pl-4 sm:text-[20px]">
            {topic}
          </h2>
        </div>
        <button>
          {isOpen ? (
            <img style={{ width: "50px" }} src={Dropup} alt="up" />
          ) : (
            <img style={{ width: "50px" }} src={Dropdown} alt="down" />
          )}
        </button>
      </div>
      {isOpen && (
        <div
          style={{ borderBottom: "3px solid rgba(131, 122, 47, 1)" }}
          className="px-10 py-4"
        >
          <ul className="list-disc font-body">
            {content.map((item, index) => (
              <li key={index} className="text-[15px] mt-6">
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export { AccordionComponent };
export default Accordion;
