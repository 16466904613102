import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import FinancierAccreditationForm from '../component/FinancierAccreditationForm';
import IndustryAccreditationForm from '../component/IndustryAccreditationForm';
import accreditationBgImage from "../assets/programmes-bg.jpg";
import apiService from '../services/apiService';

const AccreditationPage = () => {
  const [isFinancierFormOpen, setIsFinancierFormOpen] = useState(false);
  const [isIndustryFormOpen, setIsIndustryFormOpen] = useState(false);
  const [showResumeForm, setShowResumeForm] = useState(false);
  const [referenceCode, setReferenceCode] = useState('');
  const [resumeError, setResumeError] = useState('');
  const [resumeCode, setResumeCode] = useState('');
  const [resumeLoading, setResumeLoading] = useState(false);
  
  // Add state for form data
  const [financierFormData, setFinancierFormData] = useState(null);
  const [industryFormData, setIndustryFormData] = useState(null);

  const openFinancierForm = () => {
    setIsFinancierFormOpen(true);
  };

  const closeFinancierForm = () => {
    setIsFinancierFormOpen(false);
  };

  const openIndustryForm = () => {
    setIsIndustryFormOpen(true);
  };

  const closeIndustryForm = () => {
    setIsIndustryFormOpen(false);
  };

  const toggleResumeForm = () => {
    setShowResumeForm(!showResumeForm);
  };

  const handleReferenceCodeChange = (e) => {
    setReferenceCode(e.target.value);
  };

  const handleResumeSubmit = async (e) => {
    e.preventDefault();
    
    if (!resumeCode.trim()) {
      setResumeError('Please enter a reference code');
      return;
    }
    
    setResumeError('');
    setResumeLoading(true);
    
    try {
      const response = await apiService.getAccreditationDraft(resumeCode.trim());
      
      // Check if there's valid data in the response
      if (response.data && response.data.success && response.data.data && response.data.data.formData) {
        // Determine which form to open based on the draft type
        const { type, formData } = response.data.data;
        
        console.log('Retrieved draft data:', type, formData);
        
        if (type === 'financier') {
          // Store the form data in state instead of sessionStorage
          setFinancierFormData(formData);
          setIsFinancierFormOpen(true);
        } else if (type === 'industry') {
          // Store the form data in state instead of sessionStorage
          setIndustryFormData(formData);
          setIsIndustryFormOpen(true);
        } else {
          setResumeError('Unknown application type');
        }
        
        setShowResumeForm(false);
        setResumeCode('');
        setResumeError('');
      } else {
        console.error('Invalid response format:', response);
        setResumeError('Could not retrieve your application. Please check your reference code.');
      }
    } catch (error) {
      console.error('Error retrieving draft:', error);
      
      if (error.response && error.response.status === 404) {
        setResumeError('No application found with that reference code. Please check and try again.');
      } else {
        setResumeError('Failed to retrieve your application. Please try again later.');
      }
    } finally {
      setResumeLoading(false);
    }
  };

  const renderResumeForm = () => {
    if (!showResumeForm) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Resume Your Application</h3>
          <p className="mb-4">Enter your application reference code to continue where you left off:</p>
          
          <input
            type="text"
            value={resumeCode}
            onChange={(e) => setResumeCode(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
            placeholder="e.g., FIN-20250101-ABCDE-12345678"
          />
          
          {resumeError && (
            <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
              <p>{resumeError}</p>
            </div>
          )}
          
          <div className="flex justify-end space-x-3">
            <button
              onClick={toggleResumeForm}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            
            <button
              onClick={handleResumeSubmit}
              disabled={resumeLoading}
              className="px-6 py-2 bg-custom-red text-white rounded-md hover:bg-red-600"
            >
              {resumeLoading ? 'Loading...' : 'Resume'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <PageHeader 
        title="GET ACCREDITED" 
        description="Your free pre-requisite for attending AFFF SUMMIT 2025"
        backgroundImage={accreditationBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <div className="flex justify-end mb-6">
            <button
              onClick={toggleResumeForm}
              className="flex items-center text-custom-red hover:text-red-700"
            >
              <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
              </svg>
              Resume an application
            </button>
          </div>
                    
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">BECOME ACCREDITED</h2>
            </div>
            <p className="mb-4 text-sm sm:text-base">
              Accreditation is a free pre-requisite for attending the Africa Film Finance Forum. This process helps us understand your role in the industry and ensures we can provide you with the most relevant experience.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              Please select the appropriate accreditation type below:
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
              {/* Financier Card */}
              <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200 hover:shadow-xl transition-shadow">
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-3 text-custom-base">Financier Accreditation</h3>
                  <p className="mb-4 text-sm">
                    For companies or individuals interested in funding various aspects of film production, marketing, distribution, and more.
                  </p>
                  <button 
                    onClick={openFinancierForm} 
                    className="w-full block bg-custom-red text-white px-4 py-3 text-center font-bold rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300 mt-4"
                  >
                    Apply as Financier
                  </button>
                </div>
              </div>
              
              {/* Industry Player Card */}
              <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200 hover:shadow-xl transition-shadow">
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-3 text-custom-base">Industry Stakeholder Accreditation</h3>
                  <p className="mb-4 text-sm">
                    For filmmakers, producers, distributors, sales agents, film commissions, and other industry professionals.
                  </p>
                  <button 
                    onClick={openIndustryForm}
                    className="w-full block bg-[#1E2323] text-white px-4 py-3 text-center font-bold rounded-tl-lg rounded-br-lg hover:bg-gray-800 transition duration-300 mt-4"
                  >
                    Apply as Industry Stakeholder
                  </button>
                </div>
              </div>
            </div>
            
            <div className="mt-8 p-4 bg-gray-100 rounded-lg">
              <h3 className="font-bold mb-2">Important Information:</h3>
              <ul className="list-disc pl-5 space-y-2 text-sm">
                <li><strong>Accreditation is FREE</strong> and separate from badge registration.</li>
                <li>By completing accreditation, you are <strong>SOLELY applying for a badge - ACCREDITATION</strong>.</li>
                <li>If you are already accredited, please proceed to <Link to="/register" className="text-custom-red hover:underline">purchase your badge</Link>.</li>
                <li>Our team will review your application and notify you of its status.</li>
                <li>For assistance, reach out via email at <strong>events@africafilmfinanceforum.com</strong>.</li>
              </ul>
            </div>
          </section>

          {/* Financier Form Modal */}
          {isFinancierFormOpen && (
            <FinancierAccreditationForm 
              onClose={closeFinancierForm} 
              initialFormData={financierFormData} 
            />
          )}
          
          {/* Industry Form Modal */}
          {isIndustryFormOpen && (
            <IndustryAccreditationForm 
              onClose={closeIndustryForm} 
              initialFormData={industryFormData}
            />
          )}
          
          <div className="mb-8 p-5 bg-gray-50 rounded-lg border border-gray-200">
            <h3 className="text-lg font-semibold mb-3">Resume Existing Application</h3>
            <div className="flex flex-col md:flex-row items-center gap-3">
              <input
                type="text"
                placeholder="Enter your reference code"
                value={resumeCode}
                onChange={(e) => setResumeCode(e.target.value)}
                className="px-4 py-2 border rounded w-full md:w-auto"
              />
              <button
                onClick={handleResumeSubmit}
                disabled={resumeLoading}
                className="px-4 py-2 bg-custom-red text-white rounded w-full md:w-auto disabled:opacity-70"
              >
                {resumeLoading ? 'Loading...' : 'Resume Application'}
              </button>
            </div>
            {resumeError && (
              <div className="mt-2 text-red-600 text-sm">
                {resumeError}
              </div>
            )}
          </div>
          
          {renderResumeForm()}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AccreditationPage; 