import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const PromotionalBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  
  // Check local storage to see if banner was previously dismissed
  useEffect(() => {
    const bannerDismissed = localStorage.getItem('promotionalBannerDismissed');
    if (bannerDismissed) {
      setIsVisible(false);
    }
  }, []);
  
  const dismissBanner = () => {
    setIsVisible(false);
    // Set in localStorage so it stays dismissed on refresh
    localStorage.setItem('promotionalBannerDismissed', 'true');
  };
  
  if (!isVisible) return null;
  
  return (
    <div className="bg-gradient-to-r from-custom-red to-amber-700 text-white px-4 py-3 relative">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-center text-center sm:text-left">
        <div className="flex-1">
          <span className="font-bold">AFFF 2025 SUMMIT IS A SPECIAL OPPORTUNITY:</span>{' '}
          <span className="hidden sm:inline">Get accredited for free and submit your pitch for financing today!</span>
          <span className="sm:hidden">Free accreditation & pitch submission!</span>
        </div>
        
        <div className="mt-2 sm:mt-0 flex sm:flex-row space-x-4 items-center">
          <Link 
            to="/accreditation" 
            className="bg-white text-custom-red px-4 py-1 rounded-tl-lg rounded-br-lg text-sm font-bold hover:bg-amber-100 transition-colors"
          >
            GET ACCREDITED
          </Link>
          <Link 
            to="/pitch-submission" 
            className="bg-white text-custom-red px-4 py-1 rounded-tl-lg rounded-br-lg text-sm font-bold hover:bg-amber-100 transition-colors"
          >
            SUBMIT PITCH
          </Link>
        </div>
        
        <button 
          onClick={dismissBanner}
          className="absolute top-1 right-1 sm:static sm:ml-4 text-white hover:text-gray-200"
          aria-label="Close notification"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default PromotionalBanner; 