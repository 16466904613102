import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import PitchSubmissionForm from '../component/PitchSubmissionForm';
import apiService from '../services/apiService';
import pitchBgImage from "../assets/programmes-bg.jpg"; // Use an appropriate image

const PitchSubmissionPage = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [referenceCode, setReferenceCode] = useState('');
  const [resumeError, setResumeError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const openResumeModal = () => {
    setIsResumeModalOpen(true);
    setResumeError('');
  };

  const closeResumeModal = () => {
    setIsResumeModalOpen(false);
    setReferenceCode('');
  };

  const handleReferenceCodeChange = (e) => {
    setReferenceCode(e.target.value);
  };

  const resumeApplication = async () => {
    if (!referenceCode.trim()) {
      setResumeError('Please enter your reference code');
      return;
    }

    setIsLoading(true);
    try {
      const response = await apiService.getPitchDraft(referenceCode.trim());
      
      // Handle successful retrieval
      console.log('Retrieved application:', response.data);
      
      // Close the resume modal and open the form with the retrieved data
      setIsResumeModalOpen(false);
      
      // Logic to populate the form with retrieved data goes here
      // This would typically involve passing the data to the form component
      
      setIsFormOpen(true);
      
    } catch (error) {
      console.error('Error retrieving application:', error);
      setResumeError('Could not find an application with this reference code. Please check and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderResumeForm = () => {
    if (!isResumeModalOpen) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
          <h3 className="text-xl font-bold mb-4">Resume Your Application</h3>
          <p className="mb-4">Enter the reference code you received when you saved your progress:</p>
          
          <input
            type="text"
            value={referenceCode}
            onChange={handleReferenceCodeChange}
            className="w-full p-2 border rounded-md mb-4"
            placeholder="e.g., PITCH-20230101-TITLE-a1b2c3d4"
          />
          
          {resumeError && (
            <p className="text-red-600 mb-4">{resumeError}</p>
          )}
          
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={closeResumeModal}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={resumeApplication}
              disabled={isLoading}
              className={`px-4 py-2 bg-custom-red text-white rounded-md ${
                isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-red-600'
              }`}
            >
              {isLoading ? 'Loading...' : 'Resume'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <PageHeader 
        title="AFFF PITCH OPPORTUNITIES" 
        description="Submit your pitch for the Africa Film Finance Forum"
        backgroundImage={pitchBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">SUBMIT YOUR PITCH PROPOSAL</h2>
            </div>
            
            <p className="mb-4 text-sm sm:text-base">
              The AFFF Pitch Opportunities are designed to connect promising film projects with potential investors and funders.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              Whether you're developing a film production, infrastructure project, or distribution strategy, our platform offers you the chance to present your vision to key industry stakeholders.
            </p>
            <p className="mb-4 text-sm sm:text-base">
              Upon successful submission, you'll be required to purchase the AFFF Premium Elite Plus Badge, which grants you exclusive access to pitch sessions and networking opportunities.
            </p>
            
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
              <button 
                onClick={openForm} 
                className="w-full sm:w-auto bg-custom-red text-white px-4 sm:px-6 py-2 sm:py-3 text-center font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-600 transition duration-300"
              >
                Submit a New Pitch
              </button>
              <button 
                onClick={openResumeModal}
                className="w-full sm:w-auto bg-white border-2 border-custom-red text-custom-red px-4 sm:px-6 py-2 sm:py-3 text-center font-bold font-roboto text-sm sm:text-base rounded-tl-lg rounded-br-lg hover:bg-red-50 transition duration-300"
              >
                Resume Saved Application
              </button>
            </div>
          </section>

          {isFormOpen && (
            <PitchSubmissionForm onClose={closeForm} />
          )}
          
          {renderResumeForm()}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PitchSubmissionPage; 