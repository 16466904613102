import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Logo from "../assets/AFFFA1.png";
import okhla from "../assets/okhma-logo.svg";
import { Phone, Mail, Share2, MessageCircle, Image, Linkedin } from 'lucide-react';

// Define the API URL based on environment
const API_URL = import.meta.env.VITE_API_URL || 'https://updates.mtnasap.cloud/afff/api';

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubscribe = async (e) => {
    e.preventDefault();
    
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

    setIsSubmitting(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(`${API_URL}/subscribe`, { 
        email,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      if (response.data.success) {
        setSuccess(response.data.message || "You're In! We'll keep you updated!");
        setEmail("");
      } else {
        setError(response.data.message || "Subscription failed. Please try again later.");
      }
    } catch (error) {
      console.error('Newsletter subscription error:', error);
      
      if (error.response) {
        // Handle specific error responses
        switch (error.response.status) {
          case 409:
            setError("This email is already subscribed to our newsletter.");
            break;
          case 400:
            setError("Please enter a valid email address.");
            break;
          default:
            setError(error.response.data.message || "Subscription failed. Please try again later.");
        }
      } else if (error.request) {
        setError("Unable to reach our servers. Please check your internet connection.");
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <footer className="bg-[#A18749] text-black">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Logo and Contact Info */}
          <div className="flex flex-col items-center md:items-start">
            <img
              className="w-32 h-auto mb-4"
              src={Logo}
              alt="AFFF Logo"
            />
            <div className="text-center md:text-left">
              <h3 className="text-xl font-bold mb-2 font-roboto">ENQUIRIES</h3>
              <p className="flex items-center justify-center md:justify-start mb-2">
                <Phone size={20} className="mr-2" />
                <a href="tel:+2348035657616" className="mr-2">+234 803 565 7616</a> |
                <a href="tel:+2348146558068" className="ml-2">+234 814 655 8068</a>
              </p>
              <h3 className="text-xl font-bold mb-2 font-roboto">EMAIL</h3>
              <p className="flex items-center justify-center md:justify-start">
                <Mail size={20} className="mr-2" />
                <a href="mailto:events@africafilmfinanceforum.com" className="underline">
                  events@africafilmfinanceforum.com
                </a>
              </p>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="hidden lg:block font-lexend">
            <h3 className="text-xl font-bold mb-4 font-roboto">Quick Links</h3>
            <div className="grid grid-cols-2 gap-4">
              <ul className="space-y-2">
                <li><Link to="/about" className="hover:underline">ABOUT</Link></li>
                <li><Link to="/programmes" className="hover:underline">PROGRAMMES</Link></li>
                <li><Link to="/attend" className="hover:underline">ATTEND</Link></li>
              </ul>
              <ul className="space-y-2">
                <li><Link to="/exhibit/accredited-companies" className="hover:underline">EXHIBIT</Link></li>
                <li><Link to="/sponsor/opportunities" className="hover:underline">SPONSOR</Link></li>
                <li><Link to="/contact" className="hover:underline">CONTACT</Link></li>
              </ul>
            </div>
          </div>

          {/* Newsletter Subscription */}
          <div className="flex flex-col items-center lg:items-start">
            <h3 className="text-xl font-bold mb-2 text-center lg:text-left font-roboto">Stay Updated</h3>
            <p className="text-sm mb-4 text-center lg:text-left max-w-xs">
              Subscribe to our newsletter for the latest news and insights about the Africa Film Finance Forum.
            </p>
            <form onSubmit={handleSubscribe} className="w-full max-w-md">
              <div className="flex">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError(""); // Clear error when user starts typing
                    setSuccess(""); // Clear success when user starts typing
                  }}
                  className={`flex-grow px-3 py-2 rounded-l-md focus:outline-none ${
                    error ? 'border-red-500' : ''
                  }`}
                  required
                />
                <button 
                  type="submit" 
                  disabled={isSubmitting}
                  className={`bg-[#594A28] text-white px-4 py-2 rounded-r-md font-semibold 
                    ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#493d22]'}
                    transition-colors duration-200`}
                >
                  {isSubmitting ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      SUBSCRIBING...
                    </span>
                  ) : 'SUBSCRIBE'}
                </button>
              </div>
              {error && (
                <p className="mt-2 text-red-800 text-sm bg-red-100 p-2 rounded">
                  {error}
                </p>
              )}
              {success && (
                <p className="mt-2 text-green-800 text-sm bg-green-100 p-2 rounded">
                  {success}
                </p>
              )}
            </form>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="mt-8 lg:hidden">
          <ul className="flex flex-wrap justify-center gap-4 font-lexend">
            <li><Link to="/about" className="hover:underline">ABOUT</Link></li>
            <li><Link to="/programmes" className="hover:underline">PROGRAMMES</Link></li>
            <li><Link to="/attend" className="hover:underline">ATTEND</Link></li>
            <li><Link to="/exhibit/accredited-companies" className="hover:underline">EXHIBIT</Link></li>
            <li><Link to="/sponsor/opportunities" className="hover:underline">SPONSOR</Link></li>
            <li><Link to="/contact" className="hover:underline">CONTACT</Link></li>
          </ul>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-black mt-8">
        <div className="container mx-auto px-4 py-4 flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <p className="text-sm mr-2">Organized by</p>
            <a href="https://okhma.com" target="_blank" rel="noopener noreferrer">
              <img
                className="w-24 h-auto"
                src={okhla}
                alt="Okhma"
              />
            </a>
          </div>
          <p className="text-sm text-center md:text-right">
            Copyright © 2025 African Film Finance Festival | An Okhma Global Initiative
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;