import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaAngleLeft, FaAngleRight, FaTimes } from 'react-icons/fa';

// Array of images from the AFFF Connex event
const generateGalleryImages = () => {
  const images = [];
  // Loop through images 1 to 20 (adjust based on your actual number of images)
  for (let i = 1; i <= 20; i++) {
    images.push({
      url: `/connex-2024/${i}.jpg`,
      thumbnailUrl: `/connex-2024/thumbnails/${i}.jpg`
    });
  }
  return images;
};

const GALLERY_IMAGES = generateGalleryImages();

const PhotoGallery = ({ isOpen, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle keyboard navigation
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isOpen) return;
      
      switch (e.key) {
        case 'ArrowLeft':
          prevPhoto();
          break;
        case 'ArrowRight':
          nextPhoto();
          break;
        case 'Escape':
          onClose();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose]);

  const nextPhoto = () => {
    setCurrentIndex((prev) => (prev + 1) % GALLERY_IMAGES.length);
  };

  const prevPhoto = () => {
    setCurrentIndex((prev) => (prev - 1 + GALLERY_IMAGES.length) % GALLERY_IMAGES.length);
  };

  const goToPhoto = (index) => {
    setCurrentIndex(index);
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/95 z-50 flex flex-col"
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 bg-black/50">
          <h2 className="text-white text-2xl font-bold">Connex 2024 Gallery</h2>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-300 transition-colors"
          >
            <FaTimes size={24} />
          </button>
        </div>

        {/* Main Content */}
        <div className="flex-1 relative flex items-center justify-center">
          <img
            src={GALLERY_IMAGES[currentIndex].url}
            alt={`Photo ${currentIndex + 1}`}
            className="max-h-[80vh] max-w-[90vw] object-contain"
          />

          {/* Navigation Buttons */}
          <button
            onClick={prevPhoto}
            className="absolute left-4 text-white p-4 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
          >
            <FaAngleLeft size={32} />
          </button>
          <button
            onClick={nextPhoto}
            className="absolute right-4 text-white p-4 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
          >
            <FaAngleRight size={32} />
          </button>
        </div>

        {/* Thumbnails */}
        <div className="h-24 bg-black/50 p-2 overflow-x-auto">
          <div className="flex gap-2 justify-center">
            {GALLERY_IMAGES.map((photo, index) => (
              <button
                key={index}
                onClick={() => goToPhoto(index)}
                className={`flex-shrink-0 h-20 aspect-square transition-all duration-300 ${
                  currentIndex === index 
                    ? 'ring-2 ring-white scale-105' 
                    : 'opacity-50 hover:opacity-75'
                }`}
              >
                <img
                  src={photo.thumbnailUrl || photo.url} // Fallback to main image if no thumbnail
                  alt={`Thumbnail ${index + 1}`}
                  className="h-full w-full object-cover"
                />
              </button>
            ))}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PhotoGallery; 